import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

interface IConfirm {
    prompt: string;
    isOpen: boolean;
    proceed: () => void;
    cancel: () => void;
    content?: ReactNode;
}

export const ConfirmContext = createContext<[IConfirm, Dispatch<SetStateAction<IConfirm>>]>([
    {} as IConfirm,
    () => {}
]);

interface Props {
    children: ReactNode;
}

const ConfirmContextProvider = ({ children }: Props) => {
    const [confirm, setConfirm] = useState<IConfirm>({
        prompt: "",
        isOpen: false,
        proceed: () => {},
        cancel: () => {}
    });

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>
    );
};
export default ConfirmContextProvider;
