import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import SliderView from "./SliderView";
import StepsView from "./StepsView";
import { Grid } from "antd";
import { FC } from "react";

interface Props {
    data: Array<{
        id: number;
        title: string;
        description: string;
        imageSrc: string;
        delay: number;
    }>;
}

const ThirdSection: FC<Props> = ({ data }) => {
    const [ref, inView] = useInView();
    const { sm, lg } = Grid.useBreakpoint();

    return (
        <Container>
            {lg && <div className="circle-left"></div>}
            {lg && <div className="circle-right"></div>}
            <div ref={ref} className="content-container">
                <StepsView inView={inView} data={data} />
                {sm && <SliderView inView={inView} data={data} />}
            </div>
        </Container>
    );
};

export default ThirdSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    background-color: #001529;
    height: 97vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 450px) {
        height: auto;
    }

    .circle-left {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        flex-shrink: 0;
        border-radius: 958px;
        background: rgba(29, 76, 167, 0.37);
        mix-blend-mode: soft-light;
        filter: blur(200px);
    }

    .circle-right {
        position: absolute;
        right: 0;
        border-radius: 1145px;
        opacity: 0.4;
        background: rgba(173, 0, 255, 0.35);
        width: 100%;
        height: 100%;
        filter: blur(200px);
    }

    .content-container {
        max-width: 1140px;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step-section {
            height: 100%;
            padding: 20px;
            width: 50%;
            display: flex;
            .step-line {
                position: relative;
                width: 6px;
                height: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    #1677ff 50%,
                    rgba(255, 255, 255, 0) 100%
                );

                .step {
                    position: absolute;
                    left: -36px;
                    display: flex;
                    gap: 20px;
                    width: 470px;

                    svg {
                        width: 78px;
                        height: 78px;
                    }

                    .step-description {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        color: #fff;

                        & > span {
                            border-radius: 24px;
                            border: 1px solid var(--primary, #1677ff);
                            color: var(--primary, #1677ff);
                            font-family: "Raleway";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            padding: 3px 10px;
                            max-width: max-content;
                        }

                        & > h3 {
                            color: var(--primary, #1677ff);
                            font-family: "Raleway";
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            white-space: nowrap;
                        }

                        & > p {
                            color: #fff;
                            font-family: "Roboto";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 25.2px */
                        }
                    }
                }

                #step-dot-0 {
                    top: 10%;
                }

                #step-dot-1 {
                    top: 35%;
                }

                #step-dot-2 {
                    top: 63%;
                }
            }

            @media (max-width: 770px) {
                .step-line {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    .step {
                        max-width: 420px;

                        .step-description {
                            & > span {
                                font-size: 12px;
                            }

                            & > h3 {
                                font-size: 28px;
                                white-space: normal;
                            }

                            & > p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 450px) {
                width: 100%;
                margin: 20px 0;

                img {
                    max-width: 200px;
                }

                .step-line {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    .step {
                        position: relative;
                        max-width: 320px;
                        display: flex;
                        gap: 20px;

                        .step-description {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            color: #fff;

                            & > span {
                                font-size: 12px;
                            }

                            & > h3 {
                                font-size: 20px;
                                white-space: normal;
                            }

                            & > p {
                                font-size: 14px;
                                max-width: 80%;
                            }
                        }
                    }

                    #step-dot-0 {
                        top: 10%;
                    }

                    #step-dot-1 {
                        top: 35%;
                    }

                    #step-dot-2 {
                        top: 63%;
                    }
                }
            }
        }

        .slider-section {
            width: 50%;
            height: 70%;
            display: flex;
            gap: 40px;
            justify-content: center;

            .keen-slider {
                max-width: 250px;
                justify-content: center;
            }

            .keen-slider__slide {
                max-width: 250px;
                width: 100%;
            }

            .slider-dots {
                display: flex;
                padding: 10px 0;
                justify-content: center;
                flex-direction: column;
                gap: 15px;
                position: relative;
                z-index: 100;

                .slider-dot {
                    border: none;
                    width: 10px;
                    height: 10px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    margin: 0 5px;
                    padding: 5px;
                    cursor: pointer;
                }

                .slider-dot:focus {
                    outline: none;
                }

                .slider-dot.active {
                    background: #fff;
                }
            }
        }
    }
`;
