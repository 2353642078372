import { FC } from "react";
import styled, { keyframes } from "styled-components";

interface Props {
    message: string;
    color?: string;
}

export const ErrorMessage: FC<Props> = ({ message, color = "#ff3141" }) => {
    return <Container color={color}>{message}</Container>;
};

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
    }
`;

const Container = styled.div<{
    color: string;
}>`
    width: 100%;
    max-width: 365px;
    background: #fff1f0;
    border: 1px solid #ffccc7;
    border-radius: 10px;
    color: ${({ color }) => color};
    padding: 9px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 15px 0;
    animation: ${fadeIn} 0.3s ease-in-out;
`;
