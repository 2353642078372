import dayjs from "dayjs";

export const setLocaleLibs = () => {
    const localLanguage = localStorage.getItem("editor_language") || "ua";

    localLanguage === "ua" && dayjs.locale("uk");
    localLanguage === "en" && dayjs.locale("en");

    return localLanguage;
};
