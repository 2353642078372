import DoctorView from "../../components/home/DoctorView";
import Footer from "../../components/home/Footer";
import Navbar from "../../components/home/Navbar";
import PatientView from "../../components/home/PatientView";
import { useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";
import { HomeWrapper } from "./styles";

const HomePage = () => {
    const { role } = useAppSelector((state) => state.authReducer);

    return (
        <HomeWrapper>
            <Navbar />
            {role === UserRole.PATIENT ? <PatientView /> : <DoctorView />}
            <Footer />
        </HomeWrapper>
    );
};

export default HomePage;
