import React from "react";

export const CheckCircleGrey = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_872_94093)">
                <path
                    d="M11.2856 7.49902H4.71415C4.63557 7.49902 4.57129 7.56331 4.57129 7.64188V8.49903C4.57129 8.5776 4.63557 8.64188 4.71415 8.64188H11.2856C11.3641 8.64188 11.4284 8.5776 11.4284 8.49903V7.64188C11.4284 7.56331 11.3641 7.49902 11.2856 7.49902Z"
                    fill="black"
                    fillOpacity="0.45"
                />
                <path
                    d="M8 0.0712891C3.58214 0.0712891 0 3.65343 0 8.07129C0 12.4891 3.58214 16.0713 8 16.0713C12.4179 16.0713 16 12.4891 16 8.07129C16 3.65343 12.4179 0.0712891 8 0.0712891ZM8 14.7141C4.33214 14.7141 1.35714 11.7391 1.35714 8.07129C1.35714 4.40343 4.33214 1.42843 8 1.42843C11.6679 1.42843 14.6429 4.40343 14.6429 8.07129C14.6429 11.7391 11.6679 14.7141 8 14.7141Z"
                    fill="black"
                    fillOpacity="0.45"
                />
            </g>
            <defs>
                <clipPath id="clip0_872_94093">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.0712891)" />
                </clipPath>
            </defs>
        </svg>
    );
};
