import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IConsultation, IVideoChatState } from "../../models/chatTypes";
import { IObservation } from "../../models/consultationsTypes";
import { IRecipeMedicamentState } from "../../models/recipeTypes";

const initialState: IVideoChatState = {
    token: null,
    error: "",
    connectionError: false,
    sessionId: "",
    isLoading: false,
    webcamActive: false,
    completedView: false,
    errorConnectView: false,
    isLocalViewer: false,
    consultation: {
        id: "",
        date: "",
        consultation_length: 40,
        rest_length: 10,
        doctor: {
            firstName: "",
            id: "",
            lastName: "",
            specialization: [],
            surName: "",
            work_bio: "",
            publicUrl: ""
        },
        start: "",
        end: "",
        patient: {
            daasForm: {
                form: []
            },
            firstName: "",
            healthForm: null,
            id: "",
            lastName: "",
            surName: "",
            birthdate: "",
            phone: "",
            email: ""
        },
        signed: false,
        documents: []
    },
    observation: {
        diagnosis: "",
        complaints: "",
        anamnesis: "",
        condition: "",
        skin_color: "",
        mucous_color: "",
        AD: "",
        pulse: 0,
        height: 0,
        weight: 0,
        waist: 0,
        urine_amount: 0,
        notes: ""
    },
    recipeForms: [
        {
            name: "",
            unit: "",
            release_form: "",
            dosage: 0,
            period_text: "",
            receptions_per_day: 0,
            diagnosis: "",
            comment: ""
        }
    ]
};

export const videoSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setSessionID(state, action: PayloadAction<string>) {
            state.sessionId = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setSessionToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
            state.isLoading = false;
            state.connectionError = false;
        },
        setConsultation(state, action: PayloadAction<IConsultation>) {
            state.consultation = action.payload;
            state.isLoading = false;
            state.connectionError = false;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setConnectionError(state, action: PayloadAction<boolean>) {
            state.connectionError = action.payload;
            state.isLoading = false;
        },
        updateConsultationSigned(state, action: PayloadAction<boolean>) {
            state.consultation.signed = action.payload;
        },
        setWebcamActive(state, action: PayloadAction<boolean>) {
            state.webcamActive = action.payload;
        },
        setCompletedView(state, action: PayloadAction<boolean>) {
            state.completedView = action.payload;
        },
        setErrorConnectView(state, action: PayloadAction<boolean>) {
            state.errorConnectView = action.payload;
        },
        setLocalViewer(state, action: PayloadAction<boolean>) {
            state.isLocalViewer = action.payload;
        },
        setObservation(state, action: PayloadAction<IObservation>) {
            state.observation = action.payload;
        },
        updateObservation(state, action: PayloadAction<{ name: string; value: string }>) {
            if (
                action.payload.name === "pulse" ||
                action.payload.name === "height" ||
                action.payload.name === "weight" ||
                action.payload.name === "waist" ||
                action.payload.name === "urine_amount"
            ) {
                state.observation = {
                    ...state.observation,
                    [action.payload.name]: +action.payload.value
                };
            } else {
                state.observation = {
                    ...state.observation,
                    [action.payload.name]: action.payload.value
                };
            }
        },
        setPrescriptionForm(state, action: PayloadAction<IRecipeMedicamentState[]>) {
            state.recipeForms = action.payload;
        }
    }
});

export default videoSlice.reducer;
