export const StepDot = ({ ...props }) => {
    return (
        <svg
            {...props}
            width="81"
            height="81"
            viewBox="0 0 81 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_85_10004)">
                <circle cx="40.5" cy="40.5" r="5.5" fill="#021941" />
                <circle cx="40.5" cy="40.5" r="8" stroke="white" strokeWidth="5" />
            </g>
            <defs>
                <filter
                    id="filter0_d_85_10004"
                    x="0"
                    y="0"
                    width="81"
                    height="81"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="15" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0862745 0 0 0 0 0.466667 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_85_10004"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_85_10004"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
