export enum RoutesNames {
    // public routes
    HOME = "/",
    LOGIN = "/login",
    REGISTRATION = "registration",
    RESET = "reset_password",
    FAQ = "/policy",
    TERMS = "/public-offer",

    // private routes
    DOCTOR = "/doctor",
    PATIENT = "/patient",
    PROFILE = "profile",
    CHAT = "chat",
    FINANCE = "finance",
    APPOINTMENTS = "appointments",
    RECIPES = "recipes",
    RECIPE_DETAIL = "recipes/details",
    RECIPE_ORDER = "/order",
    PATIENTS = "patients",
    DISEASE = "disease",
    CONSULTATION = "consultation",
    VIDEO = "appointments/video"
}
