import axios from "axios";
import type { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import AuthService from "../services/AuthService";
import { showNotification } from "../helpers/showNotification";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
    }
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error) => {
        let originalRequest = error.config;

        if (error.response?.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                // API call for updating token
                const token = localStorage.getItem("refreshToken");
                if (token) {
                    const { data } = await AuthService.refreshToken(token);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("token", data.accessToken);
                    originalRequest.headers["Authorization"] = `Bearer ${data.accessToken}`;
                }
                return instance.request(originalRequest);
            } catch (error) {
                showNotification("Користувач не авторизований", "error");
            }
        }
        throw error;
    }
);

export default instance;
