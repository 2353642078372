import styled from "styled-components";

const TermsAndConditions = () => {
    return (
        <Container>
            <RulesWrapper>
                <h1>Публічна оферта (Угода користувача)</h1>
                <p className="policy-description">
                    Приймаючи дану оферту ви погоджуєтесь зі всіма викладеними нижче умовами і
                    підтверджуєте , що вам виповнилось 18 років, і вам зрозумілі всі положення
                    Договору і ви погоджуєтесь з ними, якщо ви відмовляєтесь від умов даного
                    Договору ви не зможете зареєструватись на Платформі.
                </p>
                <div className="policy-section">
                    <h3>Преамбула</h3>
                    <p>
                        ФОП Петюніна О.В.  пропонує фізичним та юридичним особам (далі – Користувач)
                        (разом у тексті – Сторони, окремо Сторона) приєднатися до цього договору
                        публічної оферти про надання послуг (далі – Договір) на визначених у ньому
                        умовах та відповідно до обраного переліку послуг. Цей договір є офіційною та
                        публічною пропозицією ФОП Петюніна О.В. укласти договір про надання послуг,
                        представлених на сайті https://likarweb.com/
                        <br />
                        Даний договір є публічним, тобто відповідно до статті 633 Цивільного кодексу
                        України, його умови є однаковими для всіх Користувачів незалежно від їх
                        статусу (фізична особа, юридична особа, фізична особа-підприємець) без
                        надання переваги одному Користувачу перед іншим. Шляхом укладення цього
                        Договору Користувач в повному обсязі приймає умови та порядок оформлення
                        запису на консультацію, оплати послуг, надання консультацій, інформаційних,
                        медичних послуг, отримання послуг, відповідальності за недобросовісне
                        замовлення послуги та приховування інформації та усі інші умови договору. 
                        Договір має характер публічної оферти відповідно до чинного законодавства
                        України (ст. ст. 633, 634, 641, 642 Цивільного Кодексу України), умови
                        даного Договору є однаковими та обов’язковими для всіх та застосовуються до
                        всіх послуг, які надаються на Платформі та усіх відносин між Користувачами
                        та Виконавцями, а також між Користувачами, Виконавцями та Компанією.
                        <br />
                        <br />У відповідності до ч. 2 ст. 642 Цивільного кодексу України, замовлення
                        консультації на сайті: https://likarweb.com/ є акцептом даної оферти, що
                        прирівнюється до укладення договору на умовах викладених нижче по
                        тексту. ФОП Петюніна О.В. (далі – Виконавець) з однієї сторони, керуючись
                        чинним законодавством України пропонує (публічна оферта) фізичній особі
                        (далі – Користувачу), в подальшому разом – Сторони, а кожен окремо –
                        Сторона, укласти публічний договір про надання послуг (далі – Договір) на
                        наступних умовах: 
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Терміни та визначення</h3>
                    <p>
                        <strong>«ПУБЛІЧНА ОФЕРТА» (ДОГОВІР)</strong> – цей публічний договір, у тому
                        числі усі додатки, зміни та доповнення до нього, які можуть бути внесені в
                        Договір після приєднання до нього Користувача пропозиція Виконавця,
                        адресована будь-якій дієздатній фізичній особі, яка досягла 18 років, у
                        відповідності зі статтею 633 Цивільного кодексу України, укласти з ним
                        публічний договір про надання інформаційних, консультативних послуг за
                        напрямком медицини.
                        <br />
                        <strong>«АКЦЕПТ»</strong> – повна й безумовна згода Користувача на укладення
                        даного Договору на умовах, визначених даним Договором. Акцептом за даним
                        договором являється реєстрація на сайті та передоплата за надані послуги.
                        <br />
                        <strong>«ВИКОНАВЕЦЬ»</strong> – ФОП Петюніна О.В.
                        <br />
                        <strong>«ПЛАТФОРМА»</strong> – сукупність програмного забезпечення, даних,
                        засобів проведення платежів, зберігання та доступу до інформації, які
                        містяться на Інтернет-порталі за унікальною веб-адресою:
                        https://likarweb.com/ та у мобільних додатках, задля задоволення потреб
                        Користувача щодо запису та отримання консультації медичних фахівців,
                        отримання інформаційних та навчальних матеріалів.
                        <br />
                        <strong>«КОРИСТУВАЧ»</strong> – фізична особа, Користувач сервісу, яка
                        погодилася з умовами договору та Політикою конфіденційності Виконавця, та
                        зареєструвалась у встановлений на сервісі https://likarweb.com/ спосіб, якій
                        Виконавець надає послуги у відповідності до умов даного Договору. Користувач
                        може отримувати послуги Платформи як Пацієнт або як Лікар в залежності від
                        виду послуг.
                        <br />
                        <strong>«ПОСЛУГА»</strong> – це надання Виконавцем Користувачу консультації
                        щодо стану здоров’я, обробка та аналіз діагностичних досліджень Користувача
                        і надання відповідних консультаційних заключень та рекомендацій на платній
                        основі, навчальні матеріали, статті, чек-листи. Надання інформаційних
                        матеріалів в форматі записів вебінарів. Послуги надаються за певну плату, у
                        грошовому виразі, Виконавцем Користувачу в об'ємі передбаченому даним
                        Договором.
                        <br />
                        <strong>«ЗАПИТ»</strong> - попередній запис на консультацію, всі
                        консультації проводяться за попереднім записом на сайті за посиланням
                        https://likarweb.com/ в зазначений Користувачем час і дату.
                        <br />
                        <strong>«ПАЦІЄНТ»</strong> - Користувач, який використовує Платформу з метою
                        отримання інформаційних, медичних та інших послуг, що надаються Виконавцями.
                        <br />
                        <strong>«ЛІКАР»</strong> - медик, який має відповідну кваліфікацію, в
                        установленому законом порядку займається підтримкою або відновленням
                        людського здоров'я шляхом запобігання (профілактики), розпізнавання
                        (діагностики) та лікування захворювань і травм, надає медичні, інформаційні
                        та інші види послуг Пацієнтам з використанням Платформи, а також який працює
                        в певному закладі охорони здоров’я та є зареєстрований фізичною особою –
                        підприємцем та одержав в установленому законом порядку ліцензію на
                        провадження господарської діяльності з медичної практики.
                        <br />
                        <strong>«МЕДИЧНІ ПОСЛУГИ»</strong> - послуги, які прямо чи опосередковано
                        пов’язані з медичним консультуванням та/або лікуванням Пацієнта, із
                        застосуванням засобів Платформи у форматі Телемедицини відповідно до
                        законодавства, що регулює відносини сторін в даній сфері, із забезпеченням
                        збереження медичної таємниці та конфіденційності, цілісності медичної
                        інформації про стан здоров’я Пацієнта та іншу інформацію, надану Пацієнтом.
                        <br />
                        <strong>«ТЕЛЕМЕДИЦИНА»</strong> - комплекс дій, технологій та заходів, що
                        застосовуються при наданні медичної допомоги з використанням засобів
                        дистанційного зв’язку телефонного, аудіо-, відео- або у вигляді обміну
                        електронними повідомленнями, зокрема із використанням Платформи.
                        <br />
                        <strong>«ТЕЛЕМЕДИЧНЕ КОНСУЛЬТУВАННЯ»</strong> - надання медичної допомоги
                        Пацієнту Лікарем із застосуванням Телемедицини. Під час надання Медичних
                        послуг, в т.ч. із застосуванням Телемедицини, забезпечується виконання вимог
                        законодавства України щодо захисту персональних даних та дотримання норм
                        етики та деонтології надання медичної допомоги.
                        <br />
                        <strong>«КОМПАНІЯ»</strong> - ФОП Петюніна О.В., власник прав на Платформу,
                        який діє самостійно або з залученням третіх осіб та надає Користувачам та
                        Лікарям дозвіл на використання Платформи.
                        <br />
                        <strong>«ІНФОРМАЦІЙНІ ПОСЛУГИ»</strong> - послуги, що надаються Виконавцем
                        Користувачу (в т ч Пацієнту), зокрема, але не виключно: надання інформації
                        на Платформі про лікарів, їх кваліфікацію, графік їх роботи, вартість
                        медичних послуг, здійснення електронного запису, тощо.
                        <br />
                        <strong>«ОСОБИСТИЙ КАБІНЕТ ПАЦІЄНТА»</strong> - електронний інтерфейс
                        доступу до Платформи, доступ до якого Пацієнт здійснює за допомогою
                        особистих ідентифікаторів доступу (Логін, Пароль). Особистий кабінет
                        пацієнта містить персональні дані Пацієнта та інших осіб (у разі
                        необхідності) та може містити дані про стан здоров’я Пацієнта (в т.ч., копії
                        діагностичних документів, таких як результати рентгенографії, МРТ, КТ, УЗД,
                        ЕКГ тощо), що вносяться самим Пацієнтом або Лікарем.
                        <br />
                        <strong>«ОСОБИСТИЙ КАБІНЕТ ЛІКАРЯ»</strong> - електронний інтерфейс доступу
                        до Платформи, доступ до якого Лікар здійснює за допомогою особистих
                        ідентифікаторів доступу (Логін, Пароль). Особистий кабінет лікаря містить
                        персональні дані Лікаря та може містити інші дані (інформація про вартість
                        надання Послуг тощо), що вносяться самим Лікарем.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>1. Предмет договору</h3>
                    <p>
                        1.1. На підставі та умовах визначених даним Договором Виконавець надає
                        Користувачу, а Користувач приймає послуги консультаційного характеру щодо
                        стану людського здоров’я після передоплати за послуги, Компанія надає
                        можливість Користувачу, Лікарю або Пацієнту використовувати можливості
                        Платформи для отримання Послуг або Медичних Послуг.
                        <br />
                        1.2. Інформаційні та медичні послуги надаються на платній основі за
                        попереднім запитом на консультацію. З метою отримання  послуги Користувач
                        формує та подає запит за встановленою формою та надає згоду на обробку
                        персональних даних та Інформовану добровільну згоду на проведення
                        діагностики та  лікування та використання всієї наданої Користувачем
                        інформації, в обсязі, який ним встановлено.
                        <br />
                        1.3. Всі зміни й доповнення до даного Договору опубліковуються за адресою
                        сайту https://likarweb.com/terms&conditions.
                        <br />
                        1.4. Всі умови даного Договору є обов'язковими як для всіх Користувачів,
                        Пацієнта та Лікаря так і для Виконавця. Перед початком користування Послугою
                        Користувач зобов'язаний ознайомитися з умовами даного Договору. Якщо
                        Користувач не згодний з умовами даного Договору, він не вправі користуватися
                        послугами.
                        <br />
                        1.5. У разі незгоди Користувача зі змінами, внесеними Виконавцем у даний
                        Договір або з новими тарифами на Послугу Користувач може відмовитись від
                        послуги.
                        <br />
                        1.6. Компанія надає Лікарю дозвіл на використання Платформи за її
                        функціональним призначенням в якості кінцевого Користувача, а Користувач
                        використовує Платформу відповідно до умов Договору та у випадках, визначених
                        в Договорі, оплачує за послуги.
                        <br />
                        1.7. Пацієнт використовує Платформу в межах, дозволених Компанією, в тому
                        числі: заходить на Платформу за допомогою Інтернет-браузерів чи мобільних
                        додатків; використовує функціонал Платформи з дотриманням умов Договору,
                        створює особистий кабінет Пацієнта, вносить до нього свої дані; отримує 
                        Послуги від Виконавців через Платформу, в тому числі медичну допомогу з
                        використанням Телемедицини від Лікарів.
                        <br />
                        1.8. Обсяг прав Виконавця на використання Платформи. Виконавець вправі
                        використовувати Платформу в межах, дозволених Компанією, в тому числі:
                        заходити на Платформу за допомогою Інтернет-браузерів чи мобільних додатків,
                        використовувати функціонал Платформи з дотриманням умов Договору, створювати
                        особистий кабінет Лікаря, вводити до нього свої дані та змінювати їх,
                        надавати Послуги Пацієнтам через Платформу, в тому числі медичну допомогу з
                        використанням Телемедицини.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>2. Порядок надання послуг</h3>
                    <p>
                        2.1. Користувач самостійно надає свої дані для здійснення попереднього
                        запису на консультацію на сайті https://likarweb.com/. Реєстрація
                        Користувача безкоштовна та добровільна. Послуги Платформи надаються особам,
                        яким виповнилось 18 років.
                        <br />
                        2.2. Отримавши Послугу Пацієнт ще протягом доби може знаходитись у чаті з
                        Лікарем. Пацієнт обирає лікаря та зручний для нього час консультації і
                        сплачує послугу з переліку. Це може бути онлайн-консультація, або абонемент
                        «Дружній», який включає 13 (дванадцять) консультацій за ціною дванадцяти,
                        або отримання електронного рецепту на лікарські препарати.
                        <br />
                        2.3. Компанія авторизує та надає доступ до Платформи Лікарям виключно в разі
                        наявності доказів відповідної кваліфікації. З метою перевірки кваліфікації
                        Лікаря, Компанія в процесі реєстрації/авторизації Лікаря має право вимагати
                        надання Лікарем копій дипломів, сертифікатів та будь-яких інших документів.
                        Компанія має право перевіряти чинність документів, наданих Лікарем при
                        реєстрації/авторизації. Компанія має право перевіряти кваліфікацію Лікаря
                        протягом періоду користування Платформою Лікарем. Компанія має право в
                        будь-який момент, без попередження заблокувати доступ будь-якого Лікаря до
                        Платформи, в разі наявності сумнівів щодо дійсності та/або достатності
                        доказів, щодо освіти і кваліфікації будь-якого Лікаря. Компанія не
                        зобов’язана, але має право вимагати від Виконавця надання інформації щодо
                        його юридичного статусу (наявність трудових відносин Лікаря із ЗОЗ або ФОП,
                        статусу фізичної особи-підприємця, наявність ліцензії, сертифікатів тощо).
                        <br />
                        2.4. Компанія має право в будь-який час без попередження та без пояснення
                        таких дій, відкликати ліцензію (доступ) на використання Платформи
                        Користувачем та відключити будь-який ідентифікаційний код Користувача чи
                        пароль, обраний Користувачем або призначений Платформою для доступу до
                        Платформи, якщо Компанія матиме будь-які сумніви чи буде переконана в тому,
                        що Користувач порушує умови Договору або використовує Платформу з порушенням
                        законодавства та умов Договору.
                        <br />
                        2.5. Для можливості доступу до Особистого кабінету Пацієнта, Пацієнт
                        зобов’язаний здійснити запитувані Платформою дії щодо ідентифікації, шляхом
                        введення електронної пошти та номеру мобільного телефону та підтвердження їх
                        за допомогою паролю.
                        <br />
                        2.6. Для можливості доступу до Особистого кабінету Лікаря, Лікар
                        зобов’язаний здійснити запитувані Платформою дії щодо ідентифікації, шляхом
                        введення електронної пошти та номеру мобільного телефону та підтвердження їх
                        за допомогою паролю.
                        <br />
                        2.7. Інформаційні послуги та будь-які інші послуги можуть надаватися як
                        Лікарями, так і іншими Виконавцями.
                        <br />
                        2.8. Інформаційні послуги надаються виключно з метою надання Пацієнту
                        інформації, яка необхідна для організації процесу медичного обслуговування.
                        <br />
                        2.9. Консультація проводиться за умов повної попередньої сплати за послугу
                        Кошти за інформаційні матеріали (записи вебінарів, гайди, чек-листи та інші
                        інформаційні продукти) не  повертаються.
                        <br />
                        2.10. Виконавець залишає за собою право на свій розсуд і у будь-який час
                        змінювати умови надання послуг. При цьому, Користувач у будь-який час може
                        відмовитись від послуги. Повернення грошей можливо  лише за умови якщо
                        відміна проведення за 2 доби до запланованої  зустрічі.
                        <br />
                        2.11. Якщо відмова від послуги здійснена пізніше ніж за 2 доби до
                        призначеного часу консультації проплачені за консультацію кошти не
                        повертаються.
                        <br />
                        2.12. Якщо Користувач запізнився на консультацію, вона  вважається за
                        здійснену упродовж запланованого часу. Пацієнт може перейти на консультацію
                        в будь який час, але тривалість консультацій не може буте продовженою.
                        <br />
                        2.13. Укладаючи Договір, тобто акцептуючи умови даної оферти Користувач
                        підтверджує наступне:
                        <br />
                        2.13.1 Користувач дає дозвіл на збір, обробку та передачу персональних
                        даних, дозвіл на обробку персональних даних діє протягом усього терміну дії
                        Договору, а також протягом необмеженого терміну після закінчення його дії.
                        Крім цього, укладенням договору Користувач підтверджує, що він повідомлений
                        (без додаткового повідомлення) про права, встановлених Законом України "Про
                        захист персональних даних", про цілі збору даних, а також про те, що його
                        персональні дані передаються Виконавцю з метою можливості виконання умов
                        цього Договору, можливості проведення взаєморозрахунків, а також для
                        отримання рахунків, консультаційних висновків та інших документів.
                        Користувач також погоджується з тим, що Виконавець має право надавати доступ
                        та передавати його персональні дані третім особам без будь-яких додаткових
                        повідомлень Користувача з метою виконання запиту Користувача. Обсяг прав
                        Користувача, як суб'єкта персональних даних відповідно до Закону України
                        "Про захист персональних даних" йому відомий і зрозумілий.
                        <br />
                        2.13.2 Користувач надає згоду на збір анонімізованих (без ідентифікації
                        особи) медичних даних, які будуть використовуватись виключно в наукових та
                        дослідницьких цілях, на наукових заходах і дозволяє науковцям аналізувати
                        тенденції, вдосконалювати методи лікування та розробляти нові підходи до
                        профілактики захворювань.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>3. Обмеження відповідальності</h3>
                    <p>
                        3.1. Платформа є комунікаційною платформою між Виконавцем, Лікарями та
                        Користувачами, яка призначена для сприяння та забезпечення надання
                        Виконавцем, лікарями Послуг за згодою та на запит Користувача.
                        Відповідальність за якість, ефективність, результативність, вартість надання
                        Послуг несуть Виконавець, Лікарі та Користувачі, які використовують
                        Платформу на свій власний ризик та розсуд.
                        <br />
                        3.2. Компанія не несе відповідальності за наслідки, викликані наданням
                        Користувачем інформації, яка є неповною, недостовірною; або розголошенням
                        Користувачами своєї конфіденційної інформації третім особам (в т.ч. надання
                        свого паролю, логіну); або розміщення інформації на Платформі без
                        відповідних прав на це.
                        <br />
                        3.3. Платформа надає можливість скористатися і отримати Медичні послуги у
                        формі Телемедицини, з урахуванням технічних можливостей конкретного Лікаря
                        та Виконавця.
                        <br />
                        3.4. Компанія залишає за собою право змінювати, доповнювати порядок надання
                        Послуг на Платформі, перелік та послідовність дій Користувачів щодо
                        користування Платформою, тощо. Вказані зміни та доповнення вносяться в
                        Договір, про що Користувач завчасно повідомляється шляхом розміщення на
                        Платформі відповідного повідомлення. Доступ Користувача до Платформи після
                        розміщення таких змін та доповнень на відповідній веб-сторінці Платформи є
                        свідченням того, що Користувач погоджується зі зміненими умовами.
                        <br />
                        3.5. Користувач несе відповідальність за отримання доступу до мережі
                        передавання даних, необхідного для використання Платформи. Під час
                        використання Платформи за допомогою бездротового пристрою відповідними
                        постачальниками послуг зв’язку може стягуватися плата за надсилання SMS і
                        передавання даних за тарифами мережі мобільного зв’язку, а також плата за
                        телекомунікаційні послуги, послуги зв’язку тощо.
                        <br />
                        3.6. Компанія не зобов’язана здійснювати перевірку та зміни інформації, яка
                        вноситься Користувачами під час реєстрації та користування Платформою.
                        <br />
                        3.7. Користувач самостійно несе відповідальність за збереження паролів та
                        використання облікового запису Користувача третіми особами, з вини
                        Користувача (в разі втрати або розголошення паролю Користувачем, зламу
                        доступу з боку апаратних засобів або провайдера Інтернету Користувача). В
                        разі несанкціонованого використання облікового запису Користувача на
                        Платформі з боку третіх осіб, з вини Користувача, Користувач самостійно несе
                        повну відповідальність за шкоду, заподіяну таким несанкціонованим
                        використанням третіми особам.
                        <br />
                        3.8. Компанія не гарантує Користувачу, що Платформа буде вільною від
                        помилок, вірусів або зовнішніх атак чи їх наслідків.
                        <br />
                        3.9. Користувач несе повну відповідальність за налаштування власного
                        обладнання та комп'ютерних програм для його доступу до Платформи. Користувач
                        повинен використовувати свій власний захист від вірусів.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>4. Порядок повернення коштів</h3>
                    <p>
                        4.1. Повернення коштів Виконавцем Користувачу здійснюється за умови
                        наявності підстав (неотримання послуги через її анулювання лікарем пізніше
                        ніж за 2 дні до запланованої дати надання послуги  або неможливості надання
                        послуги лікарем  вчасно з технічних причин, які у нього виникли) протягом 10
                        днів.
                        <br />
                        4.2. Повернення коштів здійснюється з урахуванням обмежень, встановлених
                        чинним законодавством.
                        <br />
                        4.3. Повернення коштів за отримані послуги, щодо яких у Користувача відсутні
                        претензії в момент отримання послуг – не здійснюється. Повернення коштів
                        провадиться в розмірі здійсненного Користувачем платежу за вирахуванням
                        комісії платіжних систем.
                        <br />
                        4.4. При платежах карткою VISA та MasterCard гроші повертаються на ту саму
                        картку, з якою було здійснено платіж. Після того, як повернеться платіж,
                        гроші з'являються на карті протягом 3-х банківських днів. Зверніть увагу, що
                        цей платіж не з'являється у виписках по карті, а просто збільшується сума на
                        карті. Такий тип повернення доступний лише протягом 30 днів після оплати.
                        <br />
                        4.5. Гроші повертаються за тими ж реквізитами, з яких було здійснено оплату
                        послуг.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>5. Права та обовязки сторін</h3>
                    <p>
                        5.1. Виконавець зобов'язується:
                        <br />
                        5.1.1. Надавати Користувачу послуги відповідно до сплаченого об'єму послуг.
                        <br />
                        5.1.2. Зберігати конфіденційність персональних даних Користувача, отриманих
                        для фахової консультації та попередньому записі.
                        <br />
                        5.1.3. Надавати Користувачу письмовий консультативний висновок в
                        електронному вигляді за результатами проведеної консультації.
                        <br />
                        5.2. Виконавець зобов’язується провести консультацію згідно призначеного
                        часу за попереднім записом.
                        <br />
                        5.3. Виконавець має право:
                        <br />
                        5.4. Припиняти надання платних послуг з моменту закінчення строку їх
                        надання.
                        <br />
                        5.5. Про зміни порядку надання послуг та їх вартість без переукладання
                        даного Договору, повідомляється на  сайті https://likarweb.com/.
                        <br />
                        5.6. Розкривати будь-які відомості, якщо це необхідно, відповідно до
                        законодавства України, на вимогу уповноважених державних установ, а також
                        для забезпечення нормального функціонування сервісу https://likarweb.com/,
                        або захисту Виконавця та Користувача(ів), якщо це не суперечить відповідним
                        Конституційним правам громадян та діючому законодавству України.
                        <br />
                        5.7. Не продовжувати консультацію більше зазначеного часу в разі запізнення
                        Користувача.
                        <br />
                        5.8. Відмовити Користувачу в разі порушення умов Договору, дзвінків та
                        повідомлень в позаробочий час Виконавцю.
                        <br />
                        5.9. Користувач зобов'язується: Вчасно оплатити послуги Виконавця в обсязі,
                        порядку і терміни, передбачені даним Договором. Дотримуватися умов даного
                        Договору. Ознайомитись з Договором, умовами надання обраних послуг,
                        дотримуватись та виконувати їх вимоги.
                        <br />
                        5.10. Користувач має право:
                        <br />
                        5.10.1. Отримувати інформацію про послуги їх вартість та умови отримання.
                        <br />
                        5.10.2. Отримати свій консультативний висновок  в особистому кабінеті.
                        <br />
                        5.12. У разі виникнення будь-яких питань, скарг або пропозицій щодо
                        отриманих послуг звертатись до Виконавця, шляхом надсилання письмового
                        повідомлення на номер телефону +380933893040 та електронну пошту
                        support@likarweb.com.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>6. Вартість послуг та порядок оплати</h3>
                    <p>
                        6.1. Вартість послуг затверджується Виконавцем і розміщена на сайті: 
                        https://likarweb.com/.
                        <br />
                        6.2. Оплата Послуг здійснюється Користувачем шляхом безготівкового платежу
                        на розрахунковий рахунок Виконавця.
                        <br />
                        6.3. Послуги надаються за умови внесення оплати в розмірі 100% за фактом
                        надходження оплати на розрахунковий рахунок Виконавця.
                        <br />
                        6.4. Користувач особисто несе відповідальність за правильність здійснених
                        ним платежів. 
                    </p>
                </div>
                <div className="policy-section">
                    <h3>7. Порядок вирішення спорів</h3>
                    <p>
                        7.1. Відносини між Сторонами регулюються законодавством України.
                        <br />
                        7.2. Всі спори, які можуть виникнути в зв'язку з цим Договором, у тому числі
                        будь-які питання стосовно їхнього виконання, укладення, дійсності чи
                        розірвання, Сторони будуть намагатися вирішити шляхом переговорів.
                        <br />
                        7.3. Користувач погоджується, що для цілей вирішення спорів між Сторонами
                        ефективним і обов'язковим для використання засобом комунікації є листування
                        з Користувачом за електронною адресою та\або телефоном +380933893040. Скарга
                        має містити повне прізвище, ім’я та по-батькові, дату народження, поштову
                        адресу Користувача, викладення обставин проблеми, аргументи Користувача, а
                        також за можливості запропонований Користувачем шлях вирішення проблеми.
                        <br />
                        7.4. У випадку направлення відповідної скарги від імені іншої особи, такий
                        Користувач має також додати копію документа, що підтверджує його
                        повноваження на такі дії.
                        <br />
                        7.5. Не пізніше 30 денного строку, Компанія направляє Користувачу лист
                        (шляхом, яким скарга була отримана), із зазначенням результату розгляду
                        скарги У разі, якщо Сторони не дійдуть згоди щодо спору протягом 30
                        (тридцяти) календарних днів, спір передається на розгляд до суду відповідно
                        до законодавства України.
                        <br />
                        7.6. Усі повідомлення до Компанії повинні бути надані Користувачем в
                        письмовій формі на адресу, зазначену в Договорі. Компанія може направляти
                        повідомлення Користувачеві на вказану ним електронну або поштову адресу, яку
                        він зазначає під час реєстрації на Платформі.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>8. Прикінцеві положення</h3>
                    <p>
                        8.1. Сторони погодились, що положення цього Договору розміщені на
                        сайті https://likarweb.com/, а свою згоду з усіма умовами Договору
                        Користувач надає у випадку замовлення послуги Виконавця.
                        <br />
                        8.2. Виконавець має право вносити зміни або доповнення до Договору в
                        будь-який час без будь-якого спеціального повідомлення, розмістивши нову
                        редакцію на сайті https://likarweb.com/. Нова редакція Договору набуває
                        чинності з моменту її розміщення, якщо інше не передбачено новою редакцією
                        Договору. Користувач повинен періодично переглядати актуальні умови
                        Договору. Якщо Користувач звертається за послугами і надалі він приймає
                        зміни Договору.
                        <br />
                        8.3. Якщо будь-яке положення Договору визнано недійсним, всі інші положення
                        залишаються незмінними та дійсними.
                        <br />
                        8.4. Будь-які питання щодо Договору або використання Платформи Користувачі
                        можуть надіслати електронною поштою на адресу support@likarweb.com., або
                        зв’язавшись за телефоном +380933893040 п'ять днів на тиждень з 9:00 до
                        18:00.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>9. Права інтелектуальної власності</h3>
                    <p>
                        9.1. Усі майнові та немайнові права на веб-сайт https://likarweb.com/
                        належать Виконавцеві.
                        <br />
                        9.2. Права інтелектуальної власності на Навчальні матеріали, використані в
                        Платформі, зокрема, але не виключно, зображення, відео, логотипи, графіка,
                        звуки, належать Виконавцю.
                        <br />
                        9.3. Виконавець надає Користувачеві обмежену, невиключну, відкличну ліцензію
                        на доступ до інформаційних матеріалів та їх використання без права передачі
                        третім особам.
                        <br />
                        9.4. Надаючи право на використання Користувачеві об’єктів інтелектуальної
                        власності для цілей виконання цього Договору, Виконавець не передає
                        будь-яких прав інтелектуальної власності на такі об’єкти та не надає дозволу
                        на їх використання в цілях інших, ніж визначені Договором.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>10. Реквізити виконавця</h3>
                    <p>ФОП Петюніна О.В.</p>
                    <p>Україна</p>
                    <p>м. Харків</p>
                    <p>Тел. +380933893040</p>
                </div>
            </RulesWrapper>
        </Container>
    );
};

export default TermsAndConditions;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RulesWrapper = styled.div`
    padding: 25px;
    display: flex;
    max-width: 960px;
    flex-direction: column;
    width: 100%;
    border-radius: 22px;
    background-color: #fff;
    font-family: "Raleway";
    gap: 25px;
    margin: 25px 0;

    @media (max-width: 490px) {
        padding: 10px;
    }

    & > h1 {
        font-size: 50px;
        font-weight: 800;
        line-height: 65px;
        color: #212121;
        margin-top: 25px;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 40px;
            line-height: 52px;
        }

        @media (max-width: 490px) {
            padding: 0 20px;
            font-size: 36px;
            line-height: 46.8px;
        }
    }

    .policy-updates {
        font-weight: 600;
        font-size: 18px;
        font-family: "Roboto";
        line-height: 25.2px;
        color: #2d2d2d;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 16px;
            line-height: 22.4px;
        }
    }

    .policy-subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        font-family: "Roboto";
        color: #8c8c8c;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 18px;
            line-height: 27px;
        }
    }

    .policy-description {
        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    strong {
        font-weight: bold;
    }

    p,
    a {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #212121;
        font-family: "Roboto";

        @media (max-width: 821px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .policy-section {
        h3 {
            font-size: 34px;
            font-weight: 700;
            line-height: 44.2px;
            font-family: "Raleway";
            color: #212121;
            margin-bottom: 12px;

            @media (max-width: 821px) {
                font-size: 32px;
                line-height: 41.6px;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 34px;
            line-height: 44.2px;
            font-family: "Raleway";
            margin-bottom: 12px;

            @media (max-width: 821px) {
                font-size: 32px;
                line-height: 41.6px;
            }
        }

        @media (max-width: 821px) {
            padding: 0 20px;
        }
    }
`;
