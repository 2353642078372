import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FifthSection from "./patient/FifthSection";
import FirstSection from "./patient/FirstSection";
import FourthSection from "./patient/FourthSection";
import NinthSection from "./patient/NinthSection";
import SecondSection from "./patient/SecondSection";
import SeventhSection from "./patient/SeventhSection";
import SixthSection from "./patient/SixthSection";
import ThirdSection from "./patient/ThirdSection";

const PatientView = () => {
    const { t } = useTranslation();

    const secondSectionData = [
        {
            id: 1,
            title: t("Convenience and efficiency"),
            subtitle: t("Forget about hours of waiting in lines and long trips to the doctor."),
            imgSrc: "/images/icons/illustration_1.svg"
        },
        {
            id: 2,
            title: t("Telemedicine"),
            subtitle: t("With us, you can consult with a doctor via video calls or chat.")
        },
        {
            id: 3,
            title: t("Ease of use"),
            subtitle: t(
                "Our online interface is easy to use even for people without technical skills."
            )
        },
        {
            id: 4,
            title: t("Qualified doctors"),
            subtitle: t(
                "We cooperate with highly qualified doctors who have many years of experience in various medical fields."
            ),
            imgSrc: "/images/icons/illustration_2.svg"
        },
        {
            id: 5,
            title: t("Privacy and security"),
            subtitle: t(
                "All your medical data and information remains confidential and protected from unauthorized access."
            ),
            imgSrc: "/images/icons/illustration_3.svg"
        },
        {
            id: 6,
            title: t("Personal care"),
            subtitle: t("We find an individual approach to each patient.")
        }
    ];

    const sliderData = [
        {
            id: 1,
            title: t("Registration"),
            description: t("Register on our website or mobile application by creating an account."),
            imageSrc: "/images/patient_slide_1.svg",
            delay: 0.7
        },
        {
            id: 1,
            title: t("Choice of doctor"),
            description: t(
                "Choose a doctor that suits you in terms of specialization and work schedule."
            ),
            imageSrc: "/images/patient_slide_2.svg",
            delay: 2
        },
        {
            id: 1,
            title: t("Make an appointment"),
            description: t(
                "Choose a time and date convenient for you, pay for the service and click 'Register'."
            ),
            imageSrc: "/images/patient_slide_3.svg",
            delay: 3.3
        }
    ];

    return (
        <PatientViewContainer>
            <FirstSection />
            <SecondSection data={secondSectionData} />
            <ThirdSection data={sliderData} />
            <FourthSection />
            <FifthSection />
            <SixthSection />
            <SeventhSection />
            <NinthSection
                title={t("For patients")}
                subtitle={t("We help you to be healthy")}
                btnText={t("Become a patient")}
            />
        </PatientViewContainer>
    );
};

export default PatientView;

const PatientViewContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    gap: 50px;

    @media (max-width: 770px) {
        gap: 25px;
    }

    @media (max-width: 450px) {
        gap: 20px;
    }
`;
