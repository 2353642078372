import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IChatChannel, IMessages } from "../../models/chatTypes";

interface IChatState {
    isLoading: boolean;
    inSearch: boolean;
    isLoadMessages: boolean;
    isSending: boolean;
    chats: IChatChannel[];
    messages: IMessages[];
    selectedChat: IChatChannel | null;
    senderId: string;
    searchingMessageID: string;
    usersOnline: string[];
}

const initialState: IChatState = {
    isLoading: false,
    inSearch: false,
    isSending: false,
    isLoadMessages: false,
    chats: [],
    messages: [],
    selectedChat: null,
    senderId: "",
    searchingMessageID: "",
    usersOnline: []
};

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setOpenSearchBar(state, action: PayloadAction<boolean>) {
            state.inSearch = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setSending(state, action: PayloadAction<boolean>) {
            state.isSending = action.payload;
        },
        setLoadMessages(state, action: PayloadAction<boolean>) {
            state.isLoadMessages = action.payload;
        },
        setChats(state, action: PayloadAction<IChatChannel[]>) {
            state.isLoading = false;
            state.chats = action.payload;
        },
        setMessages(state, action: PayloadAction<IMessages[]>) {
            state.isLoadMessages = false;
            state.isSending = false;
            state.messages = action.payload;
        },
        setSenderId(state, action: PayloadAction<string>) {
            state.senderId = action.payload;
        },
        setSelectedChat(state, action: PayloadAction<IChatChannel | null>) {
            state.selectedChat = action.payload;
        },
        setSearchingMessageID(state, action: PayloadAction<string>) {
            state.searchingMessageID = action.payload;
        },
        updateMessages(state, action: PayloadAction<IMessages>) {
            const messages = [...state.messages, action.payload].filter(
                (value, index, self) => self.findIndex((v) => v._id === value._id) === index
            );

            const currentChatIndex = state.chats.findIndex(
                (chat) => chat.chatId === action.payload.chatId
            );

            const updatedChat = {
                ...state.chats[currentChatIndex],
                lastMessage: {
                    message: action.payload.message,
                    attachments: action.payload.attachments,
                    createdAt: action.payload.createdAt
                }
            };

            const newChats = [
                ...state.chats.slice(0, currentChatIndex),
                updatedChat,
                ...state.chats.slice(currentChatIndex + 1)
            ];

            state.chats = newChats;
            state.isSending = false;
            if (state.selectedChat?.chatId === action.payload.chatId) {
                state.messages = messages;
            }
        },
        setOnlineUsers(state, action: PayloadAction<Array<string>>) {
            state.usersOnline = action.payload;
        }
    }
});

export default chatSlice.reducer;
