import { Outlet } from "react-router-dom";
import { LoginFormSection } from "../pages/login/styles";

export const LoginLayout = () => {
    return (
        <LoginFormSection>
            <Outlet />
        </LoginFormSection>
    );
};
