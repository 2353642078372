import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MobileStores } from "../../components/common/MobileStores";
import { FirstStep } from "../../components/registration/FirstStep";
import { UserRoleTabs } from "../../components/login/UserRoleTabs";
import { RoutesNames } from "../../routes/enum";
import { SecondStep } from "../../components/registration/SecondStep";
import { ThirdStep } from "../../components/registration/ThirdStep";
import { useAppDispatch } from "../../hooks/redux";
import { authSlice } from "../../store/reducers/AuthSlice";
import { useTranslation } from "react-i18next";
import { Grid } from "antd";
import { MobileAlert } from "../../components/common";

export const RegistrationPage = () => {
    const [step, setStep] = useState<number>(1);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { sm } = Grid.useBreakpoint();

    useEffect(() => {
        return () => {
            dispatch(authSlice.actions.setErrorMessage(""));
        };
    }, []);

    const handleChangeStep = () => {
        step !== 3 && setStep((prev) => prev + 1);
    };

    const onResetStep = () => {
        setStep(1);
    };

    const formItem = () => {
        switch (step) {
            case 1:
                return <FirstStep handleChangeStep={handleChangeStep} />;
            case 2:
                return <SecondStep handleChangeStep={handleChangeStep} />;
            case 3:
                return <ThirdStep />;
            default:
                return <FirstStep handleChangeStep={handleChangeStep} />;
        }
    };

    return (
        <div className="wrapper">
            {!sm && <MobileAlert />}
            <div className="form">
                <UserRoleTabs
                    title={t("Create account")}
                    step={step}
                    formItems={formItem()}
                    isRegistration
                    onResetStep={onResetStep}
                />
                <div className="sign-up_link">
                    {t("Already registered?")} <Link to={RoutesNames.LOGIN}>{t("Log in")}</Link>
                </div>
            </div>
            {sm && <MobileStores />}
        </div>
    );
};
