import { MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Drawer, Layout } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux";
import { RoutesNames } from "../../routes/enum";
import { SVGIcon } from "../SVG";
import { MobileNavLink } from "./MobileNavLinks";
import { Notification } from "./Notification";
import { AccountBlock } from "./UserMenu";

export const MobileHeader = () => {
    const [openNavMenu, setOpenNavMenu] = useState<boolean>(false);
    const { user } = useAppSelector((state) => state.userReducer);
    const navigate = useNavigate();

    const toggleNavbarMenu = () => setOpenNavMenu((prev) => !prev);

    const onViewPlatformFAQ = () => {
        navigate(`/${user.role + RoutesNames.FAQ}`);
    };

    return (
        <Container>
            <Layout.Header className="root__header">
                <LogoBlock>
                    <SVGIcon type="logo" />
                    {openNavMenu ? (
                        <MenuUnfoldOutlined
                            onClick={toggleNavbarMenu}
                            style={{ color: "#fff", fontSize: "20px" }}
                        />
                    ) : (
                        <MenuFoldOutlined
                            onClick={toggleNavbarMenu}
                            style={{ color: "#fff", fontSize: "20px" }}
                        />
                    )}
                </LogoBlock>

                <AccountInfo>
                    <UserBlock>
                        <QuestionCircleOutlined onClick={onViewPlatformFAQ} />
                        <Notification />
                        <AccountBlock isMobile />
                    </UserBlock>
                </AccountInfo>
            </Layout.Header>
            <Drawer
                placement="left"
                maskClosable
                style={{ width: "300px" }}
                width="300px"
                contentWrapperStyle={{ width: "300px" }}
                bodyStyle={{ width: "300px", padding: "0 20px" }}
                onClose={toggleNavbarMenu}
                open={openNavMenu}
            >
                <NavContainer>
                    <MobileNavLink toggleNavbarMenu={toggleNavbarMenu} />
                </NavContainer>
            </Drawer>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    width: 100%;
    z-index: 1000;

    .root__header {
        position: sticky;
        color: "#fff";
        top: 0;
        z-index: 100000000;
        width: 100%;
        display: flex;
        padding-inline: 15px;
        justify-content: space-between;
        align-items: center;
    }
`;

const LogoBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    & > svg {
        max-width: 40px;
    }
`;

const AccountInfo = styled.div`
    display: flex;
    align-items: center;
    color: #fff;

    .user {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;

        &__name {
            font-size: 14px;
            font-weight: 400;
            margin-left: 7px;
        }
    }

    svg {
        width: 20px;
        height: 100%;
        color: #fff;
        cursor: pointer;
    }
`;

const UserBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;

    .notifications {
        display: flex;
    }

    .ant-popover {
        top: 70px;
    }
    .ant-popover-inner {
        padding: 0;
    }

    .user {
        & > span {
            display: flex;
            align-items: center;
        }
    }
`;

const NavContainer = styled.div`
    .active * {
        color: #589bf8;
        font-weight: 500;
    }
`;
