import { FC, SVGAttributes } from "react";
import { Check } from "./Check";
import { CheckCircleGreen } from "./CheckCircleGreen";
import { CheckCircleGrey } from "./CheckCircleGrey";
import { Gift } from "./Gift";
import { LogoSVG } from "./LogoSVG";
import { NotifyIcon } from "./NotifyIcon";
import { StepDot } from "./StepDot";

type Props = {
    type:
        | "logo"
        | "step-dot"
        | "check"
        | "check-circle-green"
        | "check-circle-grey"
        | "notify"
        | "gift";
} & SVGAttributes<any>;

export const SVGIcon: FC<Props> = ({ type, ...props }) => {
    switch (type) {
        case "step-dot":
            return <StepDot {...props} />;
        case "logo":
            return <LogoSVG {...props} />;
        case "check":
            return <Check {...props} />;
        case "check-circle-green":
            return <CheckCircleGreen {...props} />;
        case "check-circle-grey":
            return <CheckCircleGrey {...props} />;
        case "notify":
            return <NotifyIcon {...props} />;
        case "gift":
            return <Gift {...props} />;
        default:
            return <></>;
    }
};
