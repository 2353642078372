import { useRef, useState, useLayoutEffect, ReactNode, FC } from "react";
import { useScroll, useTransform, useSpring, motion } from "framer-motion";

const calculateMinHeight = (height: number, range: number) => {
    return height + height * range;
};

const rand = (min = 0, max = 100) => Math.floor(Math.random() * (+max - +min)) + +min;

interface Props {
    children: ReactNode;
    className: string;
    topOffset?: number;
    bottomOffset?: number;
    range?: number;
}

export const ParallaxItem: FC<Props> = ({
    children,
    className,
    topOffset = -500,
    bottomOffset = 500,
    range = 0.5
}) => {
    const { scrollY } = useScroll();
    const ref = useRef<HTMLDivElement>(null);
    const [minHeight, setMinHeight] = useState<string | number>("auto");
    const [elementTop, setElementTop] = useState<number>(0);
    const springConfig = {
        damping: 100,
        stiffness: 100,
        mass: rand(1, 3)
    };

    useLayoutEffect(() => {
        if (!ref.current) return;
        const onResize = () => {
            if (!ref.current) return;
            setElementTop(ref.current.offsetTop);
            setMinHeight(calculateMinHeight(ref.current.offsetHeight, range));
        };

        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [ref, range]);

    const y = useSpring(
        useTransform(
            scrollY,
            [elementTop + topOffset, elementTop + bottomOffset],
            ["0%", `${range * 100}%`]
        ),
        springConfig
    );

    return (
        <div style={{ minHeight }} className={className}>
            <motion.div ref={ref} initial={{ y: 0 }} style={{ y }}>
                {children}
            </motion.div>
        </div>
    );
};
