import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useConfirm } from "../../hooks/useConfirm";

const CustomConfirm = () => {
    const { prompt = "", isOpen = false, proceed, cancel, content } = useConfirm();
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeydown = (e: { key: string }) => {
            if (!!proceed && isOpen && e.key === "Enter") {
                proceed();
            }
        };

        window.addEventListener("keydown", handleKeydown);
        return () => window.removeEventListener("keydown", handleKeydown);
    }, [proceed, isOpen]);

    return (
        <Modal
            open={isOpen}
            closeIcon={null}
            destroyOnClose
            cancelText={t("Cancel")}
            okText={t("Confirm")}
            cancelButtonProps={{ onClick: cancel, style: { width: "120px" } }}
            okButtonProps={{ onClick: proceed, style: { width: "120px" } }}
        >
            <ConfirmContent>
                <ExclamationCircleOutlined style={{ fontSize: 25, color: "#FF4D4F" }} />
                <ConfirmHeader>{prompt}</ConfirmHeader>
                {content && <Content>{content}</Content>}
            </ConfirmContent>
        </Modal>
    );
};

export default CustomConfirm;

const ConfirmContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 20px;
    border-radius: 15px;
    max-width: 400px;

    & > span {
        font-size: 18px;
        font-weight: 600;
        padding: 20px;
        text-align: center;
    }

    & > img {
        margin-top: 10px;
    }
`;

const ConfirmHeader = styled.p`
    width: 100%;
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
`;

const Content = styled.div`
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
`;
