import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/AuthSlice";
import userReducer from "./reducers/UserSlice";
import patientReducer from "./reducers/PatientSlice";
import doctorReducer from "./reducers/DoctorSlice";
import consultationReducer from "./reducers/ConsultationSlice";
import recordsReducer from "./reducers/RecordsSlice";
import videoChatReducer from "./reducers/VideoSlice";
import recipesReducer from "./reducers/RecipeSlice";
import chatReducer from "./reducers/ChatSlice";

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    patientReducer,
    doctorReducer,
    consultationReducer,
    recordsReducer,
    videoChatReducer,
    recipesReducer,
    chatReducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
