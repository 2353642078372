import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../../routes/enum";
import { CButton } from "../../common";

const FirstSection = () => {
    const { t } = useTranslation();
    const { sm } = Grid.useBreakpoint();
    const navigate = useNavigate();

    const goToRegistration = () => navigate(`${RoutesNames.LOGIN}/${RoutesNames.REGISTRATION}`);

    return (
        <FirstSectionContainer>
            <div className="left-view">
                <div className="left-view-title">
                    <h1>{t("Do you want to increase your customer base?")}</h1>
                    <CButton
                        width="292px"
                        align={sm ? "flex-start" : "center"}
                        onClick={goToRegistration}
                    >
                        {t("Create an account")}
                    </CButton>
                </div>
            </div>
            <div className="right-view" />
        </FirstSectionContainer>
    );
};

export default FirstSection;

const FirstSectionContainer = styled.div`
    display: flex;
    width: 100%;
    height: 70vh;

    .left-view {
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #001529;

        &-title {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 85px;

            & > h1 {
                color: #fff;
                font-family: "Raleway";
                font-size: 66px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%; /* 79.2px */
                max-width: 90%;
            }

            & > span {
                color: #fff;
                font-family: "Raleway";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 33.6px */
            }
        }
    }

    .right-view {
        position: relative;
        width: 45%;
        min-height: 300px;
        background-image: url("/images/doctor-view-home.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        img {
            width: 100%;
            max-width: 750px;
            object-fit: cover;
        }
    }

    @media (max-width: 770px) {
        width: 100%;
        height: 450px;

        .left-view {
            &-title {
                padding: 35px;

                & > h1 {
                    font-size: 36px;
                }

                & > span {
                    font-size: 16px;
                }
            }
        }

        .right-view {
            position: relative;

            .circle {
                position: absolute;
                top: -20px;
                right: 0;
                width: 478px;
                height: 478px;
                flex-shrink: 0;
                border-radius: 1078px;
                background: linear-gradient(
                    190deg,
                    rgba(16, 121, 255, 0.49) 26.96%,
                    rgba(68, 83, 255, 0) 100.79%
                );
                z-index: 2;
            }

            .parallax-item {
                position: relative;
                z-index: 5;
                right: 0;
                top: 0;
                min-height: auto !important;
                img {
                    width: 100%;
                    max-width: 750px;
                }
            }
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        height: auto;
        width: 100%;

        .left-view {
            width: 100%;

            &-title {
                padding: 35px;
                text-align: center;
                align-items: center;

                & > h1 {
                    font-size: 32px;
                    max-width: 100%;
                }

                & > span {
                    font-size: 14px;
                }
            }
        }

        .right-view {
            position: relative;
            width: 100%;

            .circle {
                position: absolute;
                top: -20px;
                right: 0;
                width: 478px;
                height: 478px;
                flex-shrink: 0;
                border-radius: 1078px;
                background: linear-gradient(
                    190deg,
                    rgba(16, 121, 255, 0.49) 26.96%,
                    rgba(68, 83, 255, 0) 100.79%
                );
                z-index: 2;
            }

            .parallax-item {
                position: relative;
                z-index: 5;
                right: 0;
                top: 0;
                min-height: auto !important;
                img {
                    width: 100%;
                    max-width: 750px;
                }
            }
        }
    }
`;
