import { QuestionCircleOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux";
import { RoutesNames } from "../../routes/enum";
import { Notification } from "./Notification";
import { AccountBlock } from "./UserMenu";

const Header = () => {
    const { user } = useAppSelector((state) => state.userReducer);
    const navigate = useNavigate();

    const onViewPlatformFAQ = () => {
        navigate(`/${user.role + RoutesNames.FAQ}`);
    };

    return (
        <Container>
            <Layout.Header className="root__header">
                <img width={250} src="/images/header-logo.png" alt="logo" />
                <AccountInfo>
                    <UserBlock>
                        <QuestionCircleOutlined onClick={onViewPlatformFAQ} />
                        <Notification />
                        <AccountBlock isMobile={false} />
                    </UserBlock>
                </AccountInfo>
            </Layout.Header>
        </Container>
    );
};

export default Header;

const Container = styled.div`
    .root__header {
        position: sticky;
        color: "#fff";
        top: 0;
        z-index: 10;
        width: 100%;
        display: flex;
        padding-inline: 15px;
        justify-content: space-between;
        align-items: center;
    }
`;

const AccountInfo = styled.div`
    display: flex;
    align-items: center;
    color: #fff;

    .user {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &__name {
            font-size: 14px;
            font-weight: 400;
            margin-left: 7px;
        }
    }

    svg {
        width: 20px;
        height: 100%;
        color: #fff;
        cursor: pointer;
    }
`;

const UserBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;

    .notifications {
        display: flex;
    }

    .ant-popover {
        top: 70px;
    }
    .ant-popover-inner {
        padding: 0;
    }

    .user {
        & > span {
            display: flex;
            align-items: center;
        }
    }
`;
