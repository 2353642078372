import { PlusOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SixthSection = () => {
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState<string | string[]>([]);

    const getExtra = () => <PlusOutlined />;

    const questionsAnswers: CollapseProps["items"] = [
        {
            key: "1",
            label: (
                <Title style={{ color: activeKey.includes("1") ? "#1677ff" : "#212121" }}>
                    {t("What is telemedicine?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "A virtual clinic provides medical and wellness services online, allowing patients to receive consultations, diagnosis and treatment remotely via the Internet."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "2",
            label: (
                <Title style={{ color: activeKey.includes("2") ? "#1677ff" : "#212121" }}>
                    {t("What services do we offer?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "The virtual clinic offers a wide range of services, including online consultations with doctors, the ability to receive electronic prescriptions, laboratory tests, telemedicine procedures and much more."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "3",
            label: (
                <Title style={{ color: activeKey.includes("3") ? "#1677ff" : "#212121" }}>
                    {t("How can I make an appointment for a consultation?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "You can use our online platform or app to book a consultation. Just choose a doctor, a convenient time and fill in the necessary information."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "4",
            label: (
                <Title style={{ color: activeKey.includes("4") ? "#1677ff" : "#212121" }}>
                    {t("How do I share my medical information securely?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "We take all necessary security measures to protect your medical information. We use encryption and apply modern security technologies to ensure the privacy and protection of your personal data."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "5",
            label: (
                <Title style={{ color: activeKey.includes("5") ? "#1677ff" : "#212121" }}>
                    {t("What forms of payment are accepted?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "We accept various forms of payment including credit cards, electronic payments and health insurance. Details of available payment methods can be found on our website."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "6",
            label: (
                <Title style={{ color: activeKey.includes("6") ? "#1677ff" : "#212121" }}>
                    {t("How soon will I receive a response from the doctor?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "We aim to provide a prompt response to all inquiries. The response time may depend on the workload of doctors and the type of question, however, we undertake to respond in a timely manner despite all appeals."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "7",
            label: (
                <Title style={{ color: activeKey.includes("7") ? "#1677ff" : "#212121" }}>
                    {t("How can I get a prescription?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "Doctors in our virtual clinic can write electronic prescriptions, which you can pick up at the nearest pharmacy or order delivery of medicines by mail."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "7a",
            label: (
                <Title style={{ color: activeKey.includes("7a") ? "#1677ff" : "#212121" }}>
                    {t("What medications can I get a prescription for?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "You can get a prescription for previously prescribed medications if there is a reference to them in your medical records or correction of therapy if it is ineffective"
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "8",
            label: (
                <Title style={{ color: activeKey.includes("8") ? "#1677ff" : "#212121" }}>
                    {t("What information will be available after the consultation?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "After the consultation, you will have access to medical records and recommendations in your online profile. You will be able to re-read the consultation and receive additional information if necessary."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "9",
            label: (
                <Title style={{ color: activeKey.includes("9") ? "#1677ff" : "#212121" }}>
                    {t("How can I evaluate the quality of virtual clinic services?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "We appreciate your opinion. After each consultation, you can leave a review and evaluation of the quality of services on our website or in the application."
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "10",
            label: (
                <Title style={{ color: activeKey.includes("10") ? "#1677ff" : "#212121" }}>
                    {t("Can I keep in touch with the doctor after the consultation?")}
                </Title>
            ),
            children: (
                <Text>
                    {t(
                        "Contact with the doctor after the consultation takes place during the day in the chat (with the exception of the hours from 22:00 to 08:00)"
                    )}
                </Text>
            ),
            extra: getExtra()
        },
        {
            key: "11",
            label: (
                <Title style={{ color: activeKey.includes("11") ? "#1677ff" : "#212121" }}>
                    {t("Does the platform provide electronic referral?")}
                </Title>
            ),
            children: <Text>{t("The platform does not provide electronic referral")}</Text>,
            extra: getExtra()
        }
    ];

    const onChange = (key: string | string[]) => {
        setActiveKey(key);
    };

    return (
        <Container>
            <div className="section-content">
                <h1>{t("Often asked")}</h1>
                <Collapse
                    activeKey={activeKey}
                    ghost
                    expandIcon={() => null}
                    onChange={onChange}
                    items={questionsAnswers}
                />
            </div>
        </Container>
    );
};

export default SixthSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .section-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 1100px;
        width: 100%;
        padding: 30px;

        h1 {
            width: 100%;
            text-align: center;
            color: #212121;
            font-family: "Raleway";
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .ant-collapse-header {
            border-bottom: 1px solid #cacaca;
            border-radius: 0 !important;
        }

        @media (max-width: 770px) {
            width: 90%;
            padding: 0;

            h1 {
                font-size: 28px;
            }
        }

        @media (max-width: 450px) {
            width: 90%;
            padding: 0;

            h1 {
                font-size: 24px;
            }
        }
    }
`;

const Title = styled.span`
    color: #212121;
    font-family: "Raleway";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */

    @media (max-width: 770px) {
        font-size: 18px;
    }
`;

const Text = styled.p`
    margin-left: 25px;
    color: #787878;
    align-self: stretch;
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */

    @media (max-width: 770px) {
        font-size: 14px;
    }
`;
