import { Grid } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MobileAlert } from "../../components/common";
import { MobileStores } from "../../components/common/MobileStores";
import { ErrorMessage } from "../../components/ErrorMessage";
import { UserRoleTabs } from "../../components/login/UserRoleTabs";
import { useAuth } from "../../contexts/AuthContextProvider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RoutesNames } from "../../routes/enum";
import { authSlice } from "../../store/reducers/AuthSlice";

export const LoginPage = () => {
    const { error } = useAppSelector((state) => state.authReducer);
    const { currentUser } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const savedValueFromLS = localStorage.getItem("savedInSystem");
    const { t } = useTranslation();
    const { sm } = Grid.useBreakpoint();

    useEffect(() => {
        if (savedValueFromLS && currentUser) {
            const isSaved = JSON.parse(savedValueFromLS);
            isSaved && navigate(`/${currentUser.role}/${RoutesNames.APPOINTMENTS}`);
        }

        return () => {
            dispatch(authSlice.actions.setErrorMessage(""));
        };
    }, [currentUser]);

    return (
        <div className="wrapper">
            {!sm && <MobileAlert />}
            <div className="form">
                <UserRoleTabs title={t("Sign in to the account")} />
                <div className="sign-up_link">
                    {t(`Don't have an account yet?`)}{" "}
                    <Link to={RoutesNames.REGISTRATION}>{t("Sign up")}</Link>
                </div>
                {error && (
                    <ErrorMessage
                        message={t(
                            "You are not registered, you entered the wrong phone number, e-mail or password"
                        )}
                    />
                )}
            </div>
            {sm && <MobileStores />}
        </div>
    );
};
