import { Input } from "antd";
import { ChangeEvent, FC, FocusEventHandler, KeyboardEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { uid } from "uid";

interface Props {
    label?: string;
    className?: string;
    type?: string;
    isPasswordField?: boolean;
    name: string;
    disabled?: boolean;
    width?: string;
    required?: boolean;
    rules?: Array<{ required: boolean; message?: string }>;
    value?: string | number;
    validateStatus?: "error" | "warning" | "";
    placeholder?: string;
    suffix?: ReactNode;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
    onPressEnter?: KeyboardEventHandler;
}

const CInput: FC<Props> = ({
    label,
    type,
    className,
    name,
    rules,
    value,
    onChange,
    placeholder,
    onBlur,
    disabled,
    onPressEnter,
    required,
    validateStatus,
    suffix = null,
    width = "100%",
    onFocus,
    isPasswordField = false,
    ...props
}) => {
    return (
        <InputContainer width={width} className={className}>
            {label && (
                <label htmlFor="input-field">
                    {required && <span className="required-mark">*</span>}
                    {label}
                </label>
            )}
            {isPasswordField ? (
                <Input.Password
                    {...props}
                    id={`input-field-${uid()}`}
                    size="large"
                    type={type}
                    name={name}
                    autoComplete="off"
                    data-lpignore="true"
                    status={validateStatus}
                    disabled={disabled}
                    onPressEnter={onPressEnter}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    required={required}
                />
            ) : (
                <Input
                    {...props}
                    id={`input-field-${uid()}`}
                    size="large"
                    type={type}
                    name={name}
                    disabled={disabled}
                    suffix={suffix}
                    autoComplete="off"
                    data-lpignore="true"
                    value={value}
                    status={validateStatus}
                    onChange={onChange}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    required={required}
                    onFocus={onFocus}
                />
            )}
        </InputContainer>
    );
};

export default CInput;

const InputContainer = styled.div<{
    width: string;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${({ width }) => width};

    .required-mark {
        color: red;
        margin-right: 3px;
    }

    label {
        font-weight: 500;
    }
    input,
    .ant-input-password {
        margin-top: 5px;
        max-height: 39.6px;
    }

    .ant-input-password {
        input {
            margin-top: 0;
        }
    }

    .ant-input-suffix {
        color: rgba(0, 0, 0, 0.45);
    }
`;
