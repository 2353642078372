import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../routes/enum";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <div className="flex-section">
                <span>© vms.ua, 2023</span>
            </div>
            <div className="flex-section">
                <Link to={RoutesNames.FAQ}>{t("Privacy Policy")}</Link>
                <Link to={RoutesNames.TERMS}>{t("Public offer")}</Link>
            </div>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flex-section {
        padding: 0 50px;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        gap: 20px;

        span,
        a {
            color: #212121;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: "Roboto";
        }

        span {
            white-space: nowrap;
        }

        a {
            font-weight: 700;
            text-align: center;
        }

        @media (max-width: 450px) {
            padding: 0 10px;
            flex-direction: column;

            a,
            span {
                font-size: 13px;
            }
        }
    }
`;
