import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Empty, Spin, Tooltip } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { FC, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getNotificationTitle } from "../../helpers/getNotificationTitle";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";
import UserService from "../../services/UserService";
import { removeAllNotifications, removeNotification } from "../../store/actions/userActions";
import { userSlice } from "../../store/reducers/UserSlice";
import PortalPopover from "../common/PortalPopover";
import { SVGIcon } from "../SVG";

const NotificationMenu: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [removing, setRemoving] = useState<boolean>(false);
    const { notification, user } = useAppSelector((state) => state.userReducer);

    const role = user.role === UserRole.DOCTOR ? UserRole.PATIENT : UserRole.DOCTOR;

    const onRemoveNotification = (id: string) => async () => {
        if (!id) return;

        try {
            setRemoving(true);
            await dispatch(removeNotification(id));
        } catch (error) {
            console.error(error);
        } finally {
            setRemoving(false);
        }
    };

    useEffect(() => {
        if (!notification.length) return;
        const newNotifications = notification.filter((el) => el.status === "new");

        if (newNotifications.length) {
            const ids = newNotifications.map((el) => el.data?.notification_id);
            UserService.updateNotification(ids).then(({ data }) => {
                setTimeout(() => {
                    dispatch(userSlice.actions.setNotification(data));
                }, 1500);
            });
        }
    }, []);

    return (
        <Container>
            <ul
                className={clsx(
                    "notify__list",
                    (!notification.length || removing) && "empty__list"
                )}
            >
                {removing && <Spin />}
                {!removing &&
                    notification.length &&
                    notification.map((notify) => (
                        <li
                            key={notify.id}
                            className={clsx("notify__item", notify.status === "new" && "new")}
                        >
                            <SVGIcon type="notify" />
                            <div className="notify__item-title">
                                <span className="message">
                                    {getNotificationTitle(notify, role)}
                                </span>
                                <span className="time">
                                    {dayjs(notify.createdAt).format("HH:mm")}
                                </span>
                            </div>
                            <Tooltip title={t("Delete")}>
                                <CloseOutlined
                                    onClick={onRemoveNotification(notify?.data?.notification_id)}
                                />
                            </Tooltip>
                        </li>
                    ))}
                {!notification.length && (
                    <li className="notify__empty">
                        <Empty description={<span>{t("There are no notifications")}</span>} />
                    </li>
                )}
            </ul>
        </Container>
    );
};

export const Notification = memo(() => {
    const { notification } = useAppSelector((state) => state.userReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [targetElement, setTargetElement] = useState(null);

    const handlePopoverToggle = (event: any) => {
        setIsOpen((prev) => !prev);
        setTargetElement(event.target);
    };

    const handleClearAndClose = async () => {
        setIsOpen(false);
    };

    const onRemoveAll = () => {
        if (notification.length) dispatch(removeAllNotifications());
    };

    const newNotify = notification.filter((notify) => notify?.status === "new");

    return (
        <div className="notifications" id="portal-root">
            <Badge count={newNotify.length}>
                <BellOutlined onClick={handlePopoverToggle} />
            </Badge>
            <PortalPopover
                isOpen={isOpen}
                title={t("Notification")}
                btnText={t("Clean")}
                onRemove={onRemoveAll}
                onClose={handleClearAndClose}
                targetElement={targetElement}
            >
                <NotificationMenu />
            </PortalPopover>
        </div>
    );
});

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 40px;
    flex: 1;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    .notify__list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .empty__list {
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .notify__empty {
        margin: 0 auto;

        svg {
            width: 100px;
        }
    }

    .new {
        background-color: #f3f3f3;
        .message {
            font-weight: 500;
        }
    }

    .notify__item {
        display: flex;
        width: 100%;
        padding: 7px 15px;
        gap: 17px;
        align-items: center;
        justify-content: space-between;

        svg:last-child {
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
            width: 15px;
        }

        &-title {
            display: flex;
            flex-direction: column;
            flex: 1;

            .message {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                font-style: normal;
                line-height: 22px; /* 157.143% */
            }
            .time {
                color: rgba(0, 0, 0, 0.45);
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
`;
