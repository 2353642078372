import { Result } from "antd";
import { Component, ErrorInfo, ReactNode } from "react";
import styled from "styled-components";
import { RoutesNames } from "../routes/enum";
import { CButton } from "./common";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
    ) as boolean;

    if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
        return window.location.reload();
    } else {
        window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
    }
};

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        retryPageLoading();
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            const goBack = () => {
                window.location.replace(process.env.REACT_APP_BASENAME + RoutesNames.LOGIN);
                window.location.reload();
                this.setState({ hasError: false });
            };
            return (
                <ErrorContainer>
                    <Result
                        status="404"
                        title={
                            <>
                                <span>404</span>
                                <span className="subtitle">Page not found</span>
                            </>
                        }
                        subTitle="Sorry, you are not authorized to access this page."
                        extra={<CButton onClick={goBack}>HOME PAGE</CButton>}
                    />
                </ErrorContainer>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

const ErrorContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;

    .ant-result-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
            font-weight: 700;
        }

        .subtitle {
            font-size: 16px;
        }
    }
`;
