import { Grid } from "antd";
import { motion } from "framer-motion";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SVGIcon } from "../../SVG";

interface Props {
    inView: boolean;
    data: Array<{
        id: number;
        title: string;
        description: string;
        imageSrc: string;
        delay: number;
    }>;
}

const StepsView: FC<Props> = ({ inView, data }) => {
    const { t } = useTranslation();
    const { sm } = Grid.useBreakpoint();

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: {
            opacity: 0,
            y: 50
        }
    };

    return (
        <div className="step-section">
            <div className="step-line">
                {data.map((item, idx) => (
                    <motion.div
                        id={`step-dot-${idx}`}
                        key={item.id}
                        className="step"
                        animate={inView ? "visible" : "hidden"}
                        variants={variants}
                        transition={{ duration: 0.7, ease: "easeOut", delay: item.delay }}
                    >
                        <div>
                            <SVGIcon type="step-dot" />
                        </div>
                        <div className="step-description">
                            <span>
                                {t("Step")} {idx + 1}
                            </span>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                            {!sm && <img src={item.imageSrc} alt={`slide-${idx + 1}`} />}
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default StepsView;
