import { lazy } from "react";
import { ProtectedDoctorRoute } from "../components/ProtectedDoctorRoute";
import { RoutesNames } from "./enum";

const ProfilePage = lazy(() => import("../pages/profile"));
const ChatPage = lazy(() => import("../pages/chat"));
const DoctorRecordsPage = lazy(() => import("../pages/records/DoctorRecords"));
const DoctorRecipesPage = lazy(() => import("../pages/recipes/DoctorRecipes"));
const PatientsPage = lazy(() => import("../pages/patients"));
const DoctorFinancePage = lazy(() => import("../pages/finance/DoctorFinance"));
const DoctorConsultationsPage = lazy(() => import("../pages/consultations/Doctor"));
const PatientDetailPage = lazy(() => import("../pages/records/PatientDetail"));
const RecipeDetailsPage = lazy(() => import("../pages/recipes/RecipeDetails"));
const PlatformRulesPage = lazy(() => import("../pages/FAQ"));

export const doctorRoutes = [
    {
        id: 1,
        name: "profile",
        path: RoutesNames.PROFILE,
        element: (
            <ProtectedDoctorRoute>
                <ProfilePage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 2,
        name: "chat",
        path: RoutesNames.CHAT,
        element: (
            <ProtectedDoctorRoute>
                <ChatPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 3,
        name: "appointments",
        path: RoutesNames.APPOINTMENTS,
        element: (
            <ProtectedDoctorRoute>
                <DoctorRecordsPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 4,
        name: "recipes",
        path: RoutesNames.RECIPES,
        element: (
            <ProtectedDoctorRoute>
                <DoctorRecipesPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 5,
        name: "patients",
        path: RoutesNames.PATIENTS,
        element: (
            <ProtectedDoctorRoute>
                <PatientsPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 6,
        name: "finance",
        path: RoutesNames.FINANCE,
        element: (
            <ProtectedDoctorRoute>
                <DoctorFinancePage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 7,
        name: "consultation",
        path: RoutesNames.CONSULTATION,
        element: (
            <ProtectedDoctorRoute>
                <DoctorConsultationsPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 8,
        name: "appointments/patientDetail",
        path: `${RoutesNames.APPOINTMENTS}/:patientId`,
        element: (
            <ProtectedDoctorRoute>
                <PatientDetailPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 10,
        name: "patients/detail",
        path: `${RoutesNames.PATIENTS}/:patientId`,
        element: (
            <ProtectedDoctorRoute>
                <PatientDetailPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 11,
        name: "recipe/patients/detail",
        path: `${RoutesNames.RECIPES}/:patientId`,
        element: (
            <ProtectedDoctorRoute>
                <PatientDetailPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 12,
        name: "recipe/details",
        path: `${RoutesNames.RECIPE_DETAIL}`,
        element: (
            <ProtectedDoctorRoute>
                <RecipeDetailsPage />
            </ProtectedDoctorRoute>
        )
    },
    {
        id: 13,
        name: "FAQ",
        path: "service_rules",
        element: (
            <ProtectedDoctorRoute>
                <PlatformRulesPage />
            </ProtectedDoctorRoute>
        )
    }
];
