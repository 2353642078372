import styled, { keyframes } from "styled-components";

export const LoginWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateX(-300px);
    }
    to {
      opacity: 1;
    }
`;

export const PreviewSection = styled.div`
    position: relative;
    width: 45%;
    min-height: 100vh;
    background: linear-gradient(31.14deg, #0c2c98 12.56%, #3f63db 96.53%);
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    font-family: "Montserrat";
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1024px) {
        width: 100%;
        display: none;
    }

    .preview-block {
        width: 100%;
        max-width: 526px;
        margin: 50px 10px 0 10px;
        flex: 1;

        h1 {
            font-weight: 700;
            font-size: 65px;
            line-height: 130%;
            margin-bottom: 30px;
            word-break: break-word;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        .preview {
            display: flex;
            flex-direction: column;
            position: relative;
            background: rgba(255, 255, 255, 0.11);
            backdrop-filter: blur(5px);
            border-radius: 35px;
            gap: 10px;
            padding: 21px;
            z-index: 10;

            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 49px;
                display: flex;
                gap: 10px;

                p {
                    width: 100%;
                }

                svg {
                    width: 60px;
                }

                span {
                    color: #4ce033;
                }
            }
        }
    }

    .person_image {
        width: 80%;
        animation: ${fadeIn} 0.5s ease-in-out;
    }
`;

export const LoginFormSection = styled.div`
    width: 100%;
    min-height: 100vh;
    background: transparent;
    font-family: "Roboto";
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 1024px) {
        width: 100%;
    }

    .wrapper {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;

        h1 {
            font-weight: 700;
            margin: 25px 0 8px 0;
            text-align: center;

            span {
                font-weight: 700;
                margin: 25px 0 8px 0;
                text-align: center;
            }
        }

        .steps {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 20px;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            font-weight: 700;
            text-shadow: none;
        }

        .ant-tabs {
            width: 100%;
        }

        .ant-tabs-nav-wrap {
            justify-content: center;
        }

        .form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 35px 0;

            a {
                text-decoration: underline;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }

            .sign-up_link {
                margin-top: 26px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.45);
                a {
                    font-weight: 500;
                    color: rgba(24, 144, 255, 1);
                }
            }

            .ant-form {
                display: flex;
                flex-direction: column;
                gap: 28px;

                .ant-select-selector {
                    height: 37px;
                }
            }

            .ant-row {
                flex-direction: column;
            }

            .ant-form-item-label {
                text-align: start;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
            }
        }

        @media (max-width: 1024px) {
            width: 93%;
        }
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .ant-checkbox {
        align-self: flex-start;
        margin-top: 4px;
    }

    .password__field {
        p {
            text-align: end;
            .link {
                text-decoration: underline;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    .code__field {
        .error {
            text-align: start;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ff4d4f;
        }
        .description {
            text-align: start;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .send__code {
            text-decoration: underline;
            font-weight: 500;
            color: #1890ff;
            cursor: pointer;
        }

        .repeat__sending {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            pointer-events: none;
        }
    }
`;
