import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

export const BreadcrumbContext = createContext<[string, Dispatch<SetStateAction<string>>]>([
    "",
    () => {}
]);

interface Props {
    children: ReactNode;
}

export const BreadcrumbContextProvider = ({ children }: Props) => {
    const [routeNameByID, setRouteNameByID] = useState<string>("");

    return (
        <BreadcrumbContext.Provider value={[routeNameByID, setRouteNameByID]}>
            {children}
        </BreadcrumbContext.Provider>
    );
};
