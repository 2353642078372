import { useState, useEffect, ReactNode, FC, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface PortalPopoverProps {
    isOpen: boolean;
    title: string;
    btnText: ReactNode;
    onClose: () => void;
    onRemove: () => void;
    targetElement: HTMLElement | null;
    children: ReactNode;
}

const PortalPopover: FC<PortalPopoverProps> = ({
    isOpen,
    onRemove,
    onClose,
    targetElement,
    title,
    btnText,
    children
}) => {
    const portalRoot = document.getElementById("portal-root");
    const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && targetElement) {
            const targetRect = targetElement.getBoundingClientRect();
            const scrollY = window.scrollY || window.pageYOffset;
            const scrollX = window.scrollX || window.pageXOffset;

            const top = targetRect.top + scrollY + targetRect.height + 10;
            const left = targetRect.left - 200 + scrollX;

            setPosition({ top, left });
        }
    }, [isOpen, targetElement]);

    useEffect(() => {
        const handler = (event: any) => {
            if (!popoverRef.current?.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    const closePopover = () => {
        onClose();
        onRemove();
    };

    return isOpen
        ? ReactDOM.createPortal(
              <PopoverContainer
                  ref={popoverRef}
                  top={position.top + "px"}
                  left={position.left + "px"}
              >
                  <div className="popover-title">{title}</div>
                  {children}
                  <button onClick={closePopover}>{btnText}</button>
              </PopoverContainer>,
              portalRoot!
          )
        : null;
};

export default PortalPopover;

const PopoverContainer = styled.div<{
    top: string;
    left: string;
}>`
    position: absolute;
    display: flex;
    height: 470px;
    flex-direction: column;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    border: 1px solid #eeeeee;
    width: 340px;
    background: #fff;
    z-index: 10000;
    border-radius: 10px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    .popover-title {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding: 15px 20px;
    }

    button {
        width: 100%;
        border: none;
        outline: none;
        border-top: 1px solid #eeeeee;
        padding: 15px;
        background-color: #fff;
        color: var(--character-danger, #ff4d4f);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        transition: all 0.2s ease-in-out;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &:hover {
            background-color: #eeeeee;
        }
    }

    @media (max-width: 480px) {
        width: 100%;
        left: 0;
    }
`;
