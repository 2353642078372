import type { ComponentProps, FC } from "react";
import { TimePicker } from "antd";
import styled from "styled-components";

const { RangePicker } = TimePicker;

export type RangeValue = Parameters<
    NonNullable<ComponentProps<typeof TimePicker.RangePicker>["onChange"]>
>[0];

interface Props {
    label?: string;
    disabled?: boolean;
    placeholder?: [string, string] | undefined;
    value: RangeValue | undefined;
    onChange: (times: RangeValue, formattedTimes: string[]) => void;
}

const CTimeRangePicker: FC<Props> = ({ label, disabled, placeholder, value, onChange }) => {
    return (
        <Container>
            {label && <label>{label}</label>}
            <RangePicker
                size="large"
                format="HH:mm"
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </Container>
    );
};

export default CTimeRangePicker;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
    }
`;
