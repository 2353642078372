import type { AxiosResponse } from "axios";
import instance from "../http";
import { AuthRoutes, SendOtpSource } from "../http/api";
import type { ILoginData, RefreshTokenState, RegistrationData } from "../models/authTypes";
import type { UserResponse } from "../models/userTypes";

export default class AuthService {
    static async login(data: ILoginData): Promise<AxiosResponse<RefreshTokenState>> {
        return instance.post(AuthRoutes.LOGIN, data);
    }

    static async sendOtpCode(
        login: string,
        source: SendOtpSource
    ): Promise<AxiosResponse<{ OTP: string }>> {
        return instance.post(AuthRoutes.SEND_OPT, { login, source });
    }

    static async verifyOtpCode(
        login: string,
        otp: string
    ): Promise<AxiosResponse<{ status: string }>> {
        return instance.post(AuthRoutes.VERIFY, { login, otp });
    }

    static async registration(data: RegistrationData): Promise<AxiosResponse<RefreshTokenState>> {
        return instance.post(AuthRoutes.REGISTER, data);
    }

    static async refreshToken(token: string): Promise<AxiosResponse<RefreshTokenState>> {
        return instance.post(AuthRoutes.REFRESH, { refreshToken: token });
    }

    static async changePassword(password: string): Promise<AxiosResponse<UserResponse>> {
        return instance.post(AuthRoutes.CHANGE_PASSWORD, { password });
    }

    static async resetPassword(
        password: string,
        login: string
    ): Promise<AxiosResponse<{ status: string }>> {
        return instance.post(AuthRoutes.RESET_PASSWORD, { password, login });
    }
}
