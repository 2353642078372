import { Popover } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../helpers/showNotification";
import { getFieldRulesResult, initValidateState } from "../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RowContainer } from "../../pages/login/styles";
import { RoutesNames } from "../../routes/enum";
import { registrationAction } from "../../store/actions/authActions";
import { authSlice } from "../../store/reducers/AuthSlice";
import { CCheckbox, CButton, CInput } from "../common";
import { ErrorMessage } from "../ErrorMessage";
import { PasswordRules } from "./PasswordRules";

export const ThirdStep = () => {
    const [ruleFields, setRuleFields] = useState<{ [key: string]: boolean }>(initValidateState);
    const { registration, isLoading, error, role } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const validateFromFields = (value: string) => {
        if (!value) {
            setRuleFields(initValidateState);
            return;
        }

        const {
            isEnLetter,
            hasNumber,
            hasCapital,
            hasSpecialSymbol,
            hasCorrectLength,
            hasLowerLetter
        } = getFieldRulesResult(value);

        if (!isEnLetter) return;

        setRuleFields({
            hasNumber,
            hasCapital,
            hasSpecialSymbol,
            hasCorrectLength,
            hasLowerLetter
        });
    };

    const getValuesFromFields = (e: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        let value = e.target.value;
        const name = e.target.name as string;

        if (name === "password") {
            validateFromFields(value);
        }

        if (name === "savedInSystem") {
            value = e.target.checked;
        }
        dispatch(authSlice.actions.updateRegisterForm({ ...registration, [name]: value }));
    };

    const onSubmitData = async () => {
        if (registration.password !== registration.repeated_password) {
            dispatch(authSlice.actions.setErrorMessage(t("Passwords do not match")));
            return;
        }

        const data = {
            login: registration.email,
            location_country: registration.location_country,
            password: registration.password,
            role: role
        };

        try {
            const userRole = await dispatch(registrationAction(data));
            localStorage.setItem("savedInSystem", JSON.stringify(registration.savedInSystem));
            navigate(`/${userRole}/${RoutesNames.PROFILE}`);
        } catch (error) {
            showNotification(t("Server error"), "error");
        }
    };

    const stepIndex = Object.values(ruleFields).filter((el) => el);

    return (
        <RowContainer>
            <Popover
                placement="bottomLeft"
                content={<PasswordRules stepIndex={stepIndex.length} ruleFields={ruleFields} />}
                title={t("Your password must includes:")}
                trigger="focus"
            >
                <CInput
                    isPasswordField
                    label={t("Create password")}
                    name="password"
                    placeholder={t("Enter a new password")}
                    onChange={getValuesFromFields}
                />
            </Popover>
            <CInput
                isPasswordField
                label={t("Repeat password")}
                placeholder={t("Repeat password")}
                name="repeated_password"
                onChange={getValuesFromFields}
            />
            <CCheckbox
                name="savedInSystem"
                checked={registration.savedInSystem}
                onChange={getValuesFromFields}
                title={<span>{t("Remember in the system")}</span>}
            />
            <CButton
                onClick={onSubmitData}
                loading={isLoading}
                disabled={!registration.password || !registration.repeated_password}
            >
                {t("Complete registration")}
            </CButton>
            {error && <ErrorMessage message={error} />}
        </RowContainer>
    );
};
