import { RoutesNames } from "./enum";

export const publicRoutes = [
    "/login/registration",
    "/login/reset_password",
    RoutesNames.LOGIN,
    RoutesNames.FAQ,
    RoutesNames.TERMS,
    RoutesNames.CONSENT_FORM,
    `${RoutesNames.GUEST}/:doctorId/:orderId/payment`
];
