import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import type { IObservationResponse, IPagination } from "../../models/consultationsTypes";
import { RecipeStatus } from "../../models/recipeTypes";
import {
    ActionsKey,
    ConsultationStatus,
    IComment,
    IConsultationResultState,
    IDocument,
    IMedicalHistory,
    IObservationConsultation,
    IPatientDetails,
    IRecords,
    IRecordsState,
    IReferralResponse,
    ITherapyResponse
} from "../../models/recordsTypes";
import { UserStatus } from "../../models/userTypes";

const initialState: IRecordsState = {
    error: "",
    total: 0,
    isLoading: false,
    currentDate: null,
    patients: [],
    times: [],
    doctors: [],
    filteredRecords: null,
    actionKey: null,
    pagination: {
        skip: 0,
        take: 10
    },
    patientDetails: {
        activeStatus: UserStatus.APPROVED,
        addresses: [],
        avatarUrl: "",
        birthdate: "",
        city: "",
        country: "",
        createdAt: "",
        email: "",
        firstName: "",
        id: "",
        languages: [],
        lastName: "",
        location_city: null,
        location_country: null,
        location_timezone: null,
        originalUrl: "",
        phone: "",
        policies: [],
        publicUrl: null,
        refreshToken: "",
        role: "",
        sex: "",
        surName: "",
        trustedPersons: [],
        updatedAt: "",
        healthForm: {
            sex: "",
            bePregnancy: false,
            pregnancyCount: 0,
            childrensCount: 0,
            abortion: false,
            abortionCount: 0,
            canceledPregnance: 0,
            smoking: "",
            alcohol: null,
            alcoholDescription: "",
            drugs: "",
            familyStatus: "",
            workStatus: "",
            lifeStyle: "",
            lifeStyleHours: "",
            arterialHypertension: null,
            parent_hypertension: null,
            mother60: "",
            parentsArterialHypertension: "",
            salt: null,
            saltLove: null,
            sleepHours: "",
            stressLevel: null,
            painLevel: null,
            anxietyLevel: null
        },
        dassForm: [],
        referrals: [],
        therapy: []
    },
    consultationResult: {
        date: "",
        start: "",
        end: "",
        status: ConsultationStatus.FINISHED,
        price: 0,
        doctor: {
            firstName: "",
            id: "",
            lastName: "",
            surName: "",
            work_bio: "",
            publicUrl: "",
            specialization: []
        },
        documents: [],
        referrals: [],
        therapy: [],
        recipe: {
            id: "",
            recipleNumber: 0,
            status: RecipeStatus.DONE,
            elements: []
        }
    },
    observation: [],
    medicalHistory: [],
    consultationObservation: {
        observation: {
            diagnosis: "",
            complaints: "",
            anamnesis: "",
            condition: "",
            skin_color: "",
            mucous_color: "",
            AD: "",
            pulse: 0,
            height: 0,
            weight: 0,
            waist: 0,
            urine_amount: 0,
            notes: "",
            patient_id: "",
            consultation_id: "",
            createdAt: "",
            doctor_id: "",
            id: "",
            updatedAt: ""
        },
        consultation: {
            date: "",
            status: ConsultationStatus.FUTURE,
            documents: [],
            signed: false
        },
        comments: []
    }
};

export const recordsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
        setCurrentDate(state, action: PayloadAction<string | null>) {
            state.currentDate = action.payload;
        },
        setActionKey(state, action: PayloadAction<ActionsKey | null>) {
            state.actionKey = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isLoading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setDoctorList(state, action: PayloadAction<Array<IRecords>>) {
            state.error = "";
            state.isLoading = false;
            state.doctors = action.payload.sort((a, b) =>
                dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
            );
        },
        setPatientList(state, action: PayloadAction<Array<IRecords>>) {
            state.error = "";
            state.isLoading = false;
            state.filteredRecords = null;
            state.patients = action.payload.sort((a, b) =>
                dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
            );
            state.times = action.payload
                .map((el) => ({
                    time: el.start,
                    status: el.status,
                    id: el.id
                }))
                .sort((a, b) => +a.time.split(":")[0] - +b.time.split(":")[0]);
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setFilteredRecord(state, action: PayloadAction<string>) {
            state.filteredRecords = state.patients.filter(
                (patient) => patient.start === action.payload
            );
        },
        setPublicPatientProfile(state, action: PayloadAction<IPatientDetails>) {
            state.error = "";
            state.isLoading = false;
            state.patientDetails = action.payload;
        },
        setConsultationResult(state, action: PayloadAction<IConsultationResultState>) {
            state.error = "";
            state.isLoading = false;
            state.consultationResult = action.payload;
        },
        setReferrals(state, action: PayloadAction<Array<IReferralResponse>>) {
            state.error = "";
            state.isLoading = false;
            state.patientDetails.referrals = action.payload;
        },
        setTherapy(state, action: PayloadAction<Array<ITherapyResponse>>) {
            state.error = "";
            state.isLoading = false;
            state.patientDetails.therapy = action.payload;
        },
        setObservation(state, action: PayloadAction<IObservationResponse[]>) {
            state.error = "";
            state.observation = action.payload;
        },
        setMedicalHistory(state, action: PayloadAction<IMedicalHistory[]>) {
            state.error = "";
            state.medicalHistory = action.payload;
        },
        setConsultationObservation(state, action: PayloadAction<IObservationConsultation>) {
            state.error = "";
            state.consultationObservation = action.payload;
        },
        setUpdatedDocuments(state, action: PayloadAction<IDocument[]>) {
            state.consultationObservation.consultation.documents = action.payload;
        },
        setConsultationComments(state, action: PayloadAction<IComment[]>) {
            state.consultationObservation.comments = action.payload;
        }
    }
});

export default recordsSlice.reducer;
