import { Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { ChangeEvent, FC, useEffect, useState } from "react";
import styled from "styled-components";
import { uid } from "uid";
import useDebounce from "../../hooks/useDebounce";
import CInput from "./CInput";

interface Props {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>, key?: number) => void;
    onSelectDrug: (value: string) => void;
    label: string;
    name: string;
    required: boolean;
}

interface IResult {
    id: number;
    name: string;
}

const SearchDrugs: FC<Props> = ({ value, onChange, label, name, required, onSelectDrug }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showHints, setShowHints] = useState<boolean>(false);
    const [results, setResults] = useState<IResult[]>([]);
    const debouncedSearchTerm = useDebounce(value, 1000);

    useEffect(() => {
        if (debouncedSearchTerm && showHints) {
            setLoading(true);

            let formData = new FormData();
            formData.append("searchText", debouncedSearchTerm);

            axios
                .post("https://tabletki.ua/ajax/search/suggest", formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                        "Accept-Language": "en-US,en;uk;ua"
                    }
                })
                .then(({ data }) => {
                    const responseResult =
                        data
                            ?.map(
                                (elem: any) =>
                                    elem.code && {
                                        id: elem.code,
                                        name: elem.name
                                    }
                            )
                            .filter((el: any) => !!el) ?? [];

                    if (responseResult?.length) {
                        setResults(responseResult);
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false));
        } else {
            setResults([]);
        }
    }, [debouncedSearchTerm, showHints]);

    const hideResults = () => {
        setTimeout(() => setShowHints(false), 1000);
    };

    return (
        <Container>
            <CInput
                label={label}
                required={required}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={hideResults}
                onFocus={() => setShowHints(true)}
            />
            {loading && (
                <span className="spinner">
                    <Spin size="small" />
                </span>
            )}
            {!!results.length && showHints && (
                <div className="results-container">
                    {results.map((drug) => (
                        <div
                            key={uid()}
                            className="result-item"
                            onClick={() => {
                                onSelectDrug(drug.name);
                                setResults([]);
                            }}
                        >
                            {drug.name}
                        </div>
                    ))}
                </div>
            )}
        </Container>
    );
};

export default SearchDrugs;

const Container = styled.div`
    position: relative;
    width: 100%;

    .spinner {
        position: absolute;
        bottom: 10px;
        right: 12px;
    }

    .results-container {
        position: absolute;
        bottom: -124px;
        width: 100%;
        height: 120px;
        border: 1px solid #eee;
        z-index: 1000;
        padding: 15px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.4);

        .result-item {
            padding: 5px 0;
            border-bottom: 1px solid #eee;
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
        }
    }
`;
