export const caching = () => {
    let version = localStorage.getItem("version");
    let lastHashCommit = document.getElementById("version")?.innerText;

    if (!lastHashCommit) return;

    if (!version) {
        localStorage.setItem("version", lastHashCommit);
        return;
    }

    if (version !== lastHashCommit) {
        if ("caches" in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                    caches.delete(name);
                });
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload();
        }
        localStorage.clear();
        localStorage.setItem("version", lastHashCommit);
    }
};
