import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import type { DefaultOptionType } from "antd/es/select";
import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
    width?: string;
    label?: string;
    mode?: "multiple" | "tags";
    value?: any;
    defaultValue?: any;
    showSearch?: boolean;
    size?: "large" | "middle" | "small";
    placeholder?: string;
    validateStatus?: "error" | "warning" | "";
    marginTop?: number;
    required?: boolean;
    suffixIcon?: ReactNode | null;
    options: Array<{ label: string; value: string }>;
    onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
}

const CSelect: FC<Props> = ({
    mode,
    width = "100%",
    label,
    value,
    defaultValue,
    size = "large",
    options = [],
    onChange,
    marginTop = 5,
    showSearch = false,
    required = false,
    suffixIcon = <DownOutlined />,
    placeholder,
    validateStatus = ""
}) => {
    return (
        <SelectContainer marginTop={marginTop} width={width}>
            {label && (
                <label htmlFor="input-field">
                    {required && <span>*</span>}
                    {label}
                </label>
            )}
            <Select
                suffixIcon={suffixIcon}
                mode={mode}
                value={value}
                size={size}
                defaultValue={defaultValue}
                showSearch={showSearch}
                placeholder={placeholder}
                onChange={onChange}
                status={validateStatus}
            >
                {options.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                    </Select.Option>
                ))}
            </Select>
        </SelectContainer>
    );
};

export default CSelect;

const SelectContainer = styled.div<{
    width: string;
    marginTop: number;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${({ width }) => width};

    .ant-select {
        width: 100%;
        margin-top: ${({ marginTop }) => marginTop + "px"};
    }

    label {
        font-weight: 500;
        span {
            color: red;
            margin-right: 5px;
        }
    }
`;
