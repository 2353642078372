import styled from "styled-components";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";

const Breadcrumb = () => {
    const { breadcrumbs, navigateTo } = useBreadcrumb();

    return (
        <Container>
            {breadcrumbs.map((item, index) => {
                if (index === 0) {
                    return null;
                } else {
                    return (
                        <span key={item.path} onClick={navigateTo(index)}>
                            {item.name} <u className="clash">/</u>
                        </span>
                    );
                }
            })}
        </Container>
    );
};

export default Breadcrumb;

const Container = styled.div`
    width: 100%;
    padding: 20px 15px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    gap: 5px;
    background-color: #fff;
    box-shadow: rgb(81, 81, 81) 1px 0px 10px -6px;

    span {
        white-space: nowrap;
        cursor: pointer;
    }

    span:last-child {
        color: #000;

        .clash {
            display: none;
        }
    }

    @media (max-width: 470px) {
        max-width: 100vw;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
