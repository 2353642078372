export const NotifyIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="32" height="32" rx="16" fill="#FA541C" />
            <path
                d="M22.4688 8.96875C22.402 8.96875 22.3334 8.98105 22.2648 9.00918L12.1328 13.0803H9.25C9.09531 13.0803 8.96875 13.2104 8.96875 13.3721V18.6279C8.96875 18.7896 9.09531 18.9197 9.25 18.9197H11.0359C10.9709 19.1236 10.9375 19.3398 10.9375 19.5596C10.9375 20.718 11.8832 21.6602 13.0469 21.6602C14.0207 21.6602 14.8416 20.9992 15.0842 20.1062L22.2666 22.9926C22.3352 23.0189 22.4037 23.033 22.4705 23.033C22.7676 23.033 23.033 22.7834 23.033 22.4494V9.55234C23.0312 9.21836 22.7676 8.96875 22.4688 8.96875ZM13.0469 20.3998C12.5811 20.3998 12.2031 20.0236 12.2031 19.5596C12.2031 19.3627 12.2717 19.1746 12.3965 19.0252L13.8889 19.6246C13.8537 20.057 13.4898 20.3998 13.0469 20.3998Z"
                fill="white"
            />
        </svg>
    );
};
