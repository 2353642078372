import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { uid } from "uid";
import { BreadcrumbContext } from "../contexts/BreadcrumbContext";

export const useBreadcrumb = () => {
    const [routeNameByID, setRouteNameByID] = useContext(BreadcrumbContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getPathName = (path: string) => {
        switch (path) {
            case "doctor":
            case "patient":
                return t("Home");
            case "appointments":
                return t("Records");
            case "recipes":
                return t("Recipes");
            case "patients":
                return t("Patients");
            case "finance":
                return t("Finance");
            case "chat":
                return t("Chat");
            case "profile":
                return t("Profile");
            case "consultation":
                return t("Online consultations");
            case "disease":
                return t("Medical history");
            case "video":
                return t("Video chat");
            case "details":
                return t("Recipe details");
            case "order":
                return t("Order a recipe");
            case "service_rules":
                return "FAQ";
            case "success":
                return t("Receipt no", { number: uid() });
            default:
                return routeNameByID;
        }
    };

    const breadcrumbs = pathname
        .split("/")
        .filter((el) => el)
        .map((path) => ({ path, name: getPathName(path) }));

    const navigateTo = (index: number) => () => {
        const link: string = breadcrumbs
            .slice(0, index + 1)
            .map((el) => `/${el.path}`)
            .join("");

        navigate(link);
    };

    return { breadcrumbs, navigateTo, setRouteNameByID };
};
