import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled, { keyframes } from "styled-components";
import { SVGIcon } from "../SVG";

const SaleComponent = () => {
    return (
        <Container>
            <SVGIcon type="gift" />
            <SaleContent>
                <SaleTitle>Безкоштовний запис до дієтолога</SaleTitle>
                <FlexContainer>
                    <SaleDescription>
                        У вас є один безкоштовний запис на консультацію до дієтолога! Встигніть
                        записатися до завершення дії абонементу!
                    </SaleDescription>
                    <Button>
                        <CalendarOutlined /> Записатися
                    </Button>
                </FlexContainer>
            </SaleContent>
        </Container>
    );
};

export default SaleComponent;

const slideLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const jumpAnimation = keyframes`
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-7px);
  }
`;

const Container = styled.div`
    width: 100%;
    border-radius: 14px;
    background: var(--primary-6, #1890ff);
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 117px;
    animation: ${slideLeftAnimation} 0.5s ease-in-out forwards,
        ${jumpAnimation} 1.5s ease-in-out infinite 1s;

    & > svg {
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 14px;
        z-index: 0;
    }
`;

const SaleContent = styled.div`
    width: 100%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;

    @media (max-width: 470px) {
        padding: 0;
    }
`;

const SaleTitle = styled.h1`
    width: 70%;
    color: var(--character-primary-inverse, #fff);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
`;

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;

    & > button {
        background-color: #fa8c16;
        color: #fff;
        border-color: #fa8c16;
        width: 200px;

        &:hover {
            background-color: #fda548;
            color: #fff !important;
            border-color: #fa8c16;
        }
    }

    @media (max-width: 470px) {
        & > button {
            margin-left: auto;
        }
    }
`;

const SaleDescription = styled.p`
    color: var(--character-primary-inverse, #fff);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    max-width: 420px;
`;
