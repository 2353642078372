import { ExportOutlined } from "@ant-design/icons";
import { Grid, Layout } from "antd";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux";
import { usePusher } from "../../hooks/usePusher";
import { RoutesNames } from "../../routes/enum";
import Breadcrumb from "./Breadcrumb";
import Header from "./Header";
import { MobileHeader } from "./MobileHeader";
import Navbar from "./Navbar";

interface Props {
    localVideoRef: MediaStream | null;
}

export const RootLayout = ({ localVideoRef }: Props) => {
    const { lg, md } = Grid.useBreakpoint();
    const navigate = useNavigate();
    const { isLocalViewer, sessionId, webcamActive } = useAppSelector(
        (state) => state.videoChatReducer
    );
    const { user } = useAppSelector((state) => state.userReducer);
    const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    usePusher();

    const backToVideoChat = () => {
        navigate(`/${user.role}/${RoutesNames.VIDEO}?sessionId=${sessionId}`);
    };

    const handleMouseDown = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleMouseMove = (e: any) => {
        const validXSize = videoPosition.x + e.movementX < 500 && videoPosition.x + e.movementX > 0;
        const validYSize = videoPosition.y + e.movementY < 500 && videoPosition.y + e.movementY > 0;

        if (isDragging && validXSize && validYSize) {
            setVideoPosition({
                x: videoPosition.x + e.movementX,
                y: videoPosition.y + e.movementY
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Wrapper isMobile={!lg}>
            <Layout className="root">
                {lg ? <Header /> : <MobileHeader />}
                <Layout>
                    {lg && <Navbar />}
                    <ContentLayout
                        isMobile={!lg}
                        isLocalViewer={isLocalViewer}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    >
                        <Breadcrumb />
                        <Layout.Content>
                            <Outlet />
                        </Layout.Content>
                        {md && !!localVideoRef && webcamActive && (
                            <div
                                className="local-video-viewer"
                                style={{
                                    position: "absolute",
                                    left: videoPosition.x + "px",
                                    top: videoPosition.y + "px"
                                }}
                                onMouseDown={handleMouseDown}
                            >
                                <video
                                    ref={(video) => {
                                        if (video) {
                                            video.srcObject = localVideoRef;
                                        }
                                    }}
                                    autoPlay
                                >
                                    Your browser does not support the video tag.
                                </video>
                                <ExportOutlined onClick={backToVideoChat} />
                            </div>
                        )}
                    </ContentLayout>
                </Layout>
            </Layout>
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    isMobile: boolean;
}>`
    height: ${({ isMobile }) => (isMobile ? "100%" : "100vh")};
    width: 100%;
    min-height: 100vh;

    .root {
        height: ${({ isMobile }) => (isMobile ? "100%" : "100vh")};
        min-height: 100vh;
        overflow-x: hidden;

        .ant-layout {
            height: 100vh;
        }
    }
`;

const ContentLayout = styled.div<{
    isMobile: boolean;
    isLocalViewer: boolean;
}>`
    display: flex;
    flex-direction: column;
    width: ${({ isMobile }) => (isMobile ? "auto" : "100%")};
    position: relative;
    margin-top: ${({ isMobile }) => (isMobile ? "60px" : "0")};
    padding-bottom: 20px;
    height: 100%;

    .ant-layout-content {
        padding: 0 15px;
        margin-top: 10px;
    }

    .content__layout {
        background-color: #fff;
        border-radius: 15px;
        height: 100%;
        width: 100%;

        .ant-tabs-content-holder {
            overflow-y: auto;
        }
    }

    .local-video-viewer {
        position: absolute;
        opacity: ${({ isLocalViewer }) => (isLocalViewer ? 1 : 0)};
        left: 0;
        bottom: 15px;
        width: 250px;
        height: 150px;
        background-color: #000;
        border-radius: 8px;
        box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.5);
        z-index: 100000;
        display: flex;
        align-items: center;
        justify-content: center;

        video {
            width: 250px;
            height: 145px;
        }

        & > span {
            position: absolute;
            background: #e2e2e2;
            z-index: 10000;
            top: 10px;
            right: 10px;
            font-size: 16px;
            border-radius: 5px;
            padding: 7px;
            cursor: pointer;
        }
    }
`;
