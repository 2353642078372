import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FirstSection from "./doctor/FirstSection";
import FifthSection from "./patient/FifthSection";
import NinthSection from "./patient/NinthSection";
import SecondSection from "./patient/SecondSection";
import SeventhSection from "./patient/SeventhSection";
import SixthSection from "./patient/SixthSection";
import ThirdSection from "./patient/ThirdSection";

const DoctorView = () => {
    const { t } = useTranslation();

    const secondSectionData = [
        {
            id: 1,
            title: t("Expansion of the patient base"),
            subtitle: t(
                "The online service will provide an opportunity to attract new patients from other regions and even countries, expanding your client base."
            ),
            imgSrc: "/images/icons/illustration_4.svg"
        },
        {
            id: 2,
            title: t("Convenient Schedule"),
            subtitle: t(
                "You independently choose the hours and days when you are ready to see patients, which increases your flexibility and work comfort."
            )
        },
        {
            id: 3,
            title: t("Electronic consultations"),
            subtitle: t("Store electronic medical records conveniently.")
        },
        {
            id: 4,
            title: t("Effective telemedicine"),
            subtitle: t(
                "The possibility of conducting online consultations allows more time to be spent on important medical aspects, and not on administrative duties."
            ),
            imgSrc: "/images/icons/illustration_5.svg"
        },
        {
            id: 5,
            title: t("Access to technology"),
            subtitle: t(
                "You will have access to updated technology and software for medical practice."
            ),
            imgSrc: "/images/icons/illustration_6.svg"
        },
        {
            id: 6,
            title: t("Cost Reduction"),
            subtitle: t("Reducing the cost of rent and equipment.")
        }
    ];

    const sliderData = [
        {
            id: 1,
            title: t("Registration and verification"),
            description: t(
                "Fill in your personal details and information about your medical license."
            ),
            imageSrc: "/images/doctor_slide_1.svg",
            delay: 0.7
        },
        {
            id: 1,
            title: t("Determining the work schedule"),
            description: t(
                "Define your work schedule, choosing the hours and days when you are available to conduct online consultations with patients."
            ),
            imageSrc: "/images/doctor_slide_2.svg",
            delay: 2
        },
        {
            id: 1,
            title: t("Make an appointment"),
            description: t(
                "After successful registration and scheduling, you are ready to start working on the platform."
            ),
            imageSrc: "/images/doctor_slide_3.svg",
            delay: 3.3
        }
    ];

    return (
        <PatientViewContainer>
            <FirstSection />
            <SecondSection data={secondSectionData} />
            <ThirdSection data={sliderData} />
            <FifthSection />
            <SixthSection />
            <SeventhSection />
            <NinthSection
                title={t("Become a doctor")}
                subtitle={t("Reach heights with us, become our partners.")}
                btnText={t("Become a doctor")}
            />
        </PatientViewContainer>
    );
};

export default DoctorView;

const PatientViewContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    gap: 50px;

    @media (max-width: 450px) {
        gap: 20px;
    }
`;
