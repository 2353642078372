import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CButton } from "../../common";

const SeventhSection = () => {
    const { t } = useTranslation();

    const redirectToPartner = () => {
        window.open("https://mmc.kh.ua/", "_blank");
    };

    return (
        <Container>
            <div className="section-content">
                <h1>{t("Our partner")}</h1>
                <div className="partner">
                    <div className="about-partner">
                        <img width={340} src="/images/molekula.jpeg" alt="partner_logo" />
                        {/* <span>
                            “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.”
                        </span>
                        <p>Name Surname</p> */}
                    </div>
                    <div className="partner-feedback">
                        {/* <h1>МОЛЕКУЛА</h1> */}
                        <span>{t("Molekula MC")}</span>
                        <CButton width="257px" align="flex-start" onClick={redirectToPartner}>
                            {t("Go to the site")}
                        </CButton>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SeventhSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .section-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1140px;
        gap: 20px;
        padding: 20px;

        @media (max-width: 770px) {
            width: 90%;
        }

        @media (max-width: 450px) {
            width: 85%;
        }

        & > h1 {
            width: 100%;
            text-align: center;
            color: #212121;
            font-family: "Raleway";
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 20px;

            @media (max-width: 770px) {
                font-size: 28px;
            }

            @media (max-width: 450px) {
                font-size: 24px;
            }
        }

        .partner {
            display: flex;
            width: 100%;
            gap: 20px;
            align-items: flex-start;
            justify-content: space-around;
            flex-direction: row;

            .about-partner {
                display: flex;
                flex-direction: column;
                gap: 15px;

                span {
                    max-width: 590px;
                    color: #1677ff;
                    font-family: "Roboto";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 44.8px */
                }
                p {
                    text-align: end;
                    width: 100%;
                    color: #1677ff;
                    font-family: "Roboto";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 28px */
                }
            }

            .partner-feedback {
                display: flex;
                flex-direction: column;
                gap: 15px;

                h1 {
                    color: #212121;
                    font-family: "Raleway";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                & > span {
                    max-width: 390px;
                    color: #787878;
                    font-family: "Roboto";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 25.2px */
                }
            }

            @media (max-width: 770px) {
                .about-partner {
                    img {
                        max-width: 200px;
                    }

                    span {
                        font-size: 20px;
                    }
                    p {
                        font-size: 14px;
                    }
                }

                .partner-feedback {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    h1 {
                        font-size: 18px;
                    }

                    & > span {
                        max-width: 390px;
                        font-size: 14px;
                    }
                }
            }

            @media (max-width: 450px) {
                flex-direction: column;

                .about-partner {
                    img {
                        max-width: 200px;
                    }

                    span {
                        font-size: 20px;
                    }
                    p {
                        font-size: 14px;
                    }
                }

                .partner-feedback {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    h1 {
                        color: #212121;
                        font-family: "Raleway";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    & > span {
                        max-width: 390px;
                        color: #787878;
                        font-family: "Roboto";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 25.2px */
                    }
                }
            }
        }
    }
`;
