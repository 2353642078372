import clsx from "clsx";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../helpers/showNotification";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RowContainer } from "../../pages/login/styles";
import { verifyOtpCodeAction } from "../../store/actions/authActions";
import { authSlice } from "../../store/reducers/AuthSlice";
import { CButton, CInput } from "../common";
import { ErrorMessage } from "../ErrorMessage";
import { RepeatedSending } from "./RepeatedSending";

interface Props {
    handleChangeStep: () => void;
}

export const SecondStep: FC<Props> = ({ handleChangeStep }) => {
    const { registration, error, isLoading } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onChangeCodeField = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(authSlice.actions.updateRegisterForm({ ...registration, code: e.target.value }));
    };

    const onSubmitData = async () => {
        try {
            await dispatch(verifyOtpCodeAction(registration.email, registration.code));
            handleChangeStep();
        } catch (error) {
            showNotification(t("Server error"), "error");
        }
    };

    return (
        <RowContainer>
            <div className="code__field">
                <CInput
                    label={t("Code")}
                    name="code"
                    type="number"
                    value={registration.code}
                    placeholder={t("Enter the 6-digit code")}
                    onChange={onChangeCodeField}
                    validateStatus={error ? "error" : ""}
                />
                <p className={clsx(error ? "error" : "description")}>
                    {error
                        ? t("The entered code is not valid")
                        : t("Enter the 6-digit code that was sent by SMS to your number")}
                    <RepeatedSending login={registration.email} />
                </p>
            </div>
            <CButton
                loading={isLoading}
                disabled={!registration.code || registration.code.length < 6}
                onClick={onSubmitData}
            >
                {t("Continue registration")}
            </CButton>
            {error && <ErrorMessage message={error} />}
        </RowContainer>
    );
};
