import { Progress } from "antd";
import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SVGIcon } from "../SVG";

interface Props {
    stepIndex: number;
    ruleFields: { [key: string]: boolean };
}

export const PasswordRules: FC<Props> = ({ stepIndex, ruleFields }) => {
    const { t } = useTranslation();

    const steps = [
        { percent: 0, strokeColor: "#ee4949", status: t("Low") },
        { percent: 20, strokeColor: "#ee4949", status: t("Low") },
        { percent: 40, strokeColor: "#fb6a03", status: t("Average") },
        { percent: 60, strokeColor: "#FAAD14", status: t("Average") },
        { percent: 80, strokeColor: "#FAAD14", status: t("Average") },
        { percent: 100, strokeColor: "#3fef59", status: t("High") }
    ];

    const rules: { [key: string]: string } = {
        hasCapital: t("One capital letter"),
        hasLowerLetter: t("One small letter"),
        hasNumber: t("One digit"),
        hasSpecialSymbol: t("One special character"),
        hasCorrectLength: t("8+ characters")
    };

    return (
        <Container>
            <ul className="list">
                {Object.keys(rules).map((key) => (
                    <li key={key} className={clsx("list__item", ruleFields[key] && "completed")}>
                        <SVGIcon
                            type={ruleFields[key] ? "check-circle-green" : "check-circle-grey"}
                        />
                        {rules[key]}
                    </li>
                ))}
            </ul>
            <div className="progress">
                <span>{t("Security:")}</span>
                <span className="status" style={{ color: steps[stepIndex].strokeColor }}>
                    {steps[stepIndex].status}
                </span>
            </div>
            <Progress success={steps[stepIndex]} showInfo={false} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 0 25px 15px 25px;

    .list {
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        gap: 3px;

        &__item {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .completed {
            color: #52c41a;
            text-decoration: line-through;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .status {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
`;
