import { useEffect, useState } from "react";

export const useCountdown = (value: number) => {
    const [countDown, setCountDown] = useState(value);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((prev) => {
                if (prev === 0) {
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return countDown;
};
