import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../helpers/showNotification";
import { checkEmailField } from "../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SendOtpSource } from "../../http/api";
import { RowContainer } from "../../pages/login/styles";
import { sendVerifyCodeAction } from "../../store/actions/authActions";
import { authSlice } from "../../store/reducers/AuthSlice";
import { CButton, CInput } from "../common";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
    handleChangeStep: () => void;
}

export const FirstStep: FC<Props> = ({ handleChangeStep }) => {
    const { reset, error, isLoading } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getValueFromFields = (e: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const name: string = e.target.name as string;
        const value: string = e.target.value;
        dispatch(authSlice.actions.updateResetPasswordForm({ ...reset, [name]: value }));
    };

    const onSubmitData = async () => {
        try {
            await dispatch(sendVerifyCodeAction(reset.email, SendOtpSource.RESET_PASSWORD));
            handleChangeStep();
        } catch (error) {
            showNotification(t("Invalid phone number or email"), "error");
        }
    };

    const checkEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.includes("@")) {
            const isValid = checkEmailField(value);
            !isValid &&
                dispatch(
                    authSlice.actions.setErrorMessage(t("The mailbox is not entered correctly"))
                );
        }
    };

    return (
        <RowContainer>
            <CInput
                label={t("Phone/E-mail")}
                name="email"
                value={reset.email}
                validateStatus={error ? "error" : ""}
                placeholder={t("Enter your phone number or email address")}
                onChange={getValueFromFields}
                onBlur={checkEmail}
            />
            <CButton onClick={onSubmitData} loading={isLoading} disabled={!reset.email}>
                {t("Recover password")}
            </CButton>
            {error && <ErrorMessage message={error} />}
        </RowContainer>
    );
};
