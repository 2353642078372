import { lazy } from "react";
import { ProtectedPatientRoute } from "../components/ProtectedPatientRoute";
import { RoutesNames } from "./enum";

const ProfilePage = lazy(() => import("../pages/profile"));
const ChatPage = lazy(() => import("../pages/chat"));
const ConsultationPage = lazy(() => import("../pages/consultations/Patient"));
const DoctorDetailPage = lazy(() => import("../pages/consultations/DoctorDetail"));
const PatientRecordsPage = lazy(() => import("../pages/records/PatientRecords"));
const PatientRecipesPage = lazy(() => import("../pages/recipes/PatientRecipes"));
const PatientDiseasePage = lazy(() => import("../pages/disease"));
const PatientFinancePage = lazy(() => import("../pages/finance/PatientFinance"));
const AppointmentResultPage = lazy(() => import("../pages/records/AppointmentResult"));
const RecipeOrdersPage = lazy(() => import("../pages/recipes/RecipeOrders"));
const PatientOrdersPage = lazy(() => import("../pages/recipes/PatientOrders"));
const RecipeDetailsPage = lazy(() => import("../pages/recipes/RecipeDetails"));
const MedicalCardDetailsPage = lazy(() => import("../pages/disease/MedicalCardDetails"));
const PlatformRulesPage = lazy(() => import("../pages/FAQ"));
const SuccessfulPage = lazy(() => import("../pages/consultations/SuccessfulPage"));
const RecipesSuccessfulPage = lazy(() => import("../pages/recipes/SuccessfulPage"));

export const patientRoutes = [
    {
        id: 1,
        name: "profile",
        path: RoutesNames.PROFILE,
        element: (
            <ProtectedPatientRoute>
                <ProfilePage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 2,
        name: "consultation",
        path: RoutesNames.CONSULTATION,
        element: (
            <ProtectedPatientRoute>
                <ConsultationPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 3,
        name: "doctor-details",
        path: `${RoutesNames.CONSULTATION}/:doctorId`,
        element: (
            <ProtectedPatientRoute>
                <DoctorDetailPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 4,
        name: "chat",
        path: RoutesNames.CHAT,
        element: (
            <ProtectedPatientRoute>
                <ChatPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 5,
        name: "appointments",
        path: RoutesNames.APPOINTMENTS,
        element: (
            <ProtectedPatientRoute>
                <PatientRecordsPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 6,
        name: "recipes",
        path: RoutesNames.RECIPES,
        element: (
            <ProtectedPatientRoute>
                <PatientRecipesPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 7,
        name: "disease",
        path: RoutesNames.DISEASE,
        element: (
            <ProtectedPatientRoute>
                <PatientDiseasePage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 8,
        name: "finance",
        path: RoutesNames.FINANCE,
        element: (
            <ProtectedPatientRoute>
                <PatientFinancePage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 10,
        name: "appointments/detail",
        path: `${RoutesNames.APPOINTMENTS}/:consultationId`,
        element: (
            <ProtectedPatientRoute>
                <AppointmentResultPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 11,
        name: "recipes/order",
        path: RoutesNames.RECIPES + RoutesNames.RECIPE_ORDER,
        element: (
            <ProtectedPatientRoute>
                <RecipeOrdersPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 12,
        name: "recipes/order/detail",
        path: `${RoutesNames.RECIPES + RoutesNames.RECIPE_ORDER}/:doctorId`,
        element: (
            <ProtectedPatientRoute>
                <PatientOrdersPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 13,
        name: "recipe/details",
        path: `${RoutesNames.RECIPE_DETAIL}`,
        element: (
            <ProtectedPatientRoute>
                <RecipeDetailsPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 14,
        name: "medical-card/details",
        path: `${RoutesNames.DISEASE}/:doctorId`,
        element: (
            <ProtectedPatientRoute>
                <MedicalCardDetailsPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 15,
        name: "FAQ",
        path: "service_rules",
        element: (
            <ProtectedPatientRoute>
                <PlatformRulesPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 16,
        name: "success_appointment",
        path: `${RoutesNames.CONSULTATION}/:doctorId/success`,
        element: (
            <ProtectedPatientRoute>
                <SuccessfulPage />
            </ProtectedPatientRoute>
        )
    },
    {
        id: 17,
        name: "recipes/order/success",
        path: `${RoutesNames.RECIPES + RoutesNames.RECIPE_ORDER}/:doctorId/success`,
        element: (
            <ProtectedPatientRoute>
                <RecipesSuccessfulPage />
            </ProtectedPatientRoute>
        )
    }
];
