import { Popover } from "antd";
import { useCallback, useState } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import { LanguageList } from "./LanguageList";

export const Language = () => {
    const { language, setCurrentLanguage } = useLanguage();
    const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);
    const [locale, setLocale] = useState<string>(language);

    const handleOpenLanguageMenu = (newOpen: boolean) => {
        setOpenLanguageMenu(newOpen);
    };

    const onChangeLocaleLanguage = useCallback(
        (language: string) => () => {
            setLocale(language);
            setCurrentLanguage(language);
            setOpenLanguageMenu(false);
        },
        []
    );

    const imageSrc =
        locale === "ua" ? "/images/ukraine-flag.png" : "/images/united-kingdom-flag.png";

    return (
        <Popover
            id="language"
            arrow={false}
            content={<LanguageList language={locale} onChangeLanguage={onChangeLocaleLanguage} />}
            placement="bottomLeft"
            trigger="click"
            open={openLanguageMenu}
            onOpenChange={handleOpenLanguageMenu}
        >
            <img width={30} src={imageSrc} alt="flag" />
        </Popover>
    );
};
