import type { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
    text: ReactNode;
    type: "info" | "warning" | "success";
}

const CAlert: FC<Props> = ({ text, type }) => {
    return (
        <Wrapper>
            <Container className={`custom-alert ${type}`}>
                <span>{text}</span>
            </Container>
        </Wrapper>
    );
};

export default CAlert;

const Wrapper = styled.div`
    .info {
        background: #e6f7ff;
        border: 1px solid var(--primary-3, #91d5ff);
    }
    .warning {
        border: 1px solid var(--dust-red-2, #ffccc7);
        background: var(--dust-red-1, #fff1f0);
    }
    .success {
        border: 1px solid #b7eb8f;
        background: #f6ffed;
    }
`;

const Container = styled.div`
    padding: 9px 16px;
    border-radius: 10px;
    margin: 20px;

    span {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }
`;
