import { DatePicker, DatePickerProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { FC } from "react";
import styled from "styled-components";

interface Props {
    width?: string;
    label?: string;
    name: string;
    picker?: "year" | "date" | "month" | "quarter" | "time" | "week";
    className?: string;
    value?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (
        value: DatePickerProps["value"] | RangePickerProps["value"],
        dateString: [string, string] | string
    ) => void;
}

const CDatePicker: FC<Props> = ({
    label,
    width = "100%",
    className,
    name,
    picker,
    value,
    placeholder,
    onChange,
    disabled = false,
    required = false
}) => {
    const dateValue = value ? dayjs(value) : undefined;
    return (
        <DatePickerContainer width={width}>
            {label && (
                <label>
                    {required && <span className="required-mark">*</span>}
                    {label}
                </label>
            )}
            <DatePicker
                disabled={disabled}
                name={name}
                size="large"
                picker={picker}
                value={dateValue}
                className={className}
                placeholder={placeholder}
                style={{ width: "100%", fontSize: "12px" }}
                onChange={onChange}
            />
        </DatePickerContainer>
    );
};

export default CDatePicker;

const DatePickerContainer = styled.div<{
    width: string;
}>`
    position: relative;
    width: ${({ width }) => width};

    label {
        font-weight: 500;

        .required-mark {
            color: red;
            margin-right: 3px;
        }
    }
    .ant-picker {
        margin-top: 5px;
    }
`;
