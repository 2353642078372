import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../../routes/enum";
import { CButton } from "../../common";
import { ParallaxItem } from "../ParallaxItem";

const FirstSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { sm, lg } = Grid.useBreakpoint();

    const goToRegistration = () => navigate(`${RoutesNames.LOGIN}/${RoutesNames.REGISTRATION}`);

    return (
        <FirstSectionContainer>
            <div className="left-view">
                <div className="left-view-title">
                    <h1>{t("We are near wherever you are")}</h1>
                    <span>{t("We help you to be healthy")}</span>
                    <CButton
                        width="292px"
                        align={sm ? "flex-start" : "center"}
                        onClick={goToRegistration}
                    >
                        {t("Create an account")}
                    </CButton>
                </div>
            </div>
            <div className="right-view">
                {lg && <div className="circle"></div>}
                <ParallaxItem className="parallax-item" range={0.6}>
                    <img src="/images/preview-patient.png" alt="preview" />
                </ParallaxItem>
            </div>
        </FirstSectionContainer>
    );
};

export default FirstSection;

const FirstSectionContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80vh;

    .left-view {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-title {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 85px;

            & > h1 {
                color: #212121;
                font-family: "Raleway";
                font-size: 66px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
            }

            & > span {
                color: #787878;
                font-family: "Roboto";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }

    .right-view {
        position: relative;
        width: 60%;

        .circle {
            position: absolute;
            top: -320px;
            left: 180px;
            width: 1078px;
            height: 1078px;
            flex-shrink: 0;
            border-radius: 1078px;
            background: linear-gradient(
                190deg,
                rgba(16, 121, 255, 0.49) 26.96%,
                rgba(68, 83, 255, 0) 100.79%
            );
            z-index: 2;
        }

        .parallax-item {
            position: absolute;
            z-index: 5;
            right: 0;
            top: 40px;
            img {
                width: 100%;
                max-width: 750px;
            }
        }
    }

    @media (max-width: 770px) {
        height: 400px;

        .left-view {
            &-title {
                padding-left: 35px;

                & > h1 {
                    font-size: 36px;
                }

                & > span {
                    font-size: 16px;
                }
            }
        }

        .right-view {
            .circle {
                top: -210px;
                max-height: 790px;
                max-width: 790px;
            }
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        height: auto;
        width: 100%;

        .left-view {
            width: 100%;

            &-title {
                padding: 35px;
                text-align: center;

                & > h1 {
                    font-size: 32px;
                }

                & > span {
                    font-size: 14px;
                }
            }
        }

        .right-view {
            position: relative;
            width: 100%;

            .circle {
                position: absolute;
                top: -20px;
                right: 0;
                width: 478px;
                height: 478px;
                flex-shrink: 0;
                border-radius: 1078px;
                background: linear-gradient(
                    190deg,
                    rgba(16, 121, 255, 0.49) 26.96%,
                    rgba(68, 83, 255, 0) 100.79%
                );
                z-index: 2;
            }

            .parallax-item {
                position: relative;
                z-index: 5;
                right: 0;
                top: 0;
                min-height: auto !important;
                img {
                    width: 100%;
                    max-width: 750px;
                }
            }
        }
    }
`;
