import GoogleAnalytics from "react-ga";

const TRACKER_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "G-QL98KNHSGZ";
GoogleAnalytics.initialize(TRACKER_ID);

interface WithTrackerOptions {
    [key: string]: any;
}

export const trackPage = (path: string, options: WithTrackerOptions = {}) => {
    const BASENAME = process.env.REACT_APP_BASENAME || "";
    const page = `${BASENAME}${path}`;

    GoogleAnalytics.set({
        page,
        ...options
    });
    GoogleAnalytics.pageview(page);
};
