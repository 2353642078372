import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
    width?: string;
    title?: ReactNode;
    name?: string;
    checked?: boolean;
    placeholder?: string;
    onChange: (e: CheckboxChangeEvent) => void;
}

const CCheckbox: FC<Props> = ({ width = "100%", name, onChange, title, checked }) => {
    return (
        <CheckboxContainer width={width}>
            <Checkbox name={name} checked={checked} onChange={onChange}>
                {title}
            </Checkbox>
        </CheckboxContainer>
    );
};

export default CCheckbox;

const CheckboxContainer = styled.div<{
    width: string;
}>`
    position: relative;
    width: ${({ width }) => width};

    label {
        font-weight: 500;
    }
`;
