import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const MobileStores = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <p className="store-title">{t("Download the app and get more features")}</p>
            <div className="buttons">
                <button>
                    <img width={194} src="/images/google_store.png" alt="google" />
                </button>
                <button>
                    <img width={194} src="/images/apple_store.png" alt="apple" />
                </button>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 35px 0 15px 0;
    width: 100%;

    .store-title {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
    }

    .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 12px;

        button {
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    @media (max-width: 470px) {
        .buttons {
            flex-direction: column;
        }
    }
`;
