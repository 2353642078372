import { FC, ReactElement, Suspense, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContextProvider";
import { useAppSelector } from "../hooks/redux";
import { UserRole } from "../models/authTypes";
import { RoutesNames } from "../routes/enum";
import { trackPage } from "../utils/withTracker";
import { Loader } from "./common/Loader";

interface Props {
    children: ReactElement<any, any>;
}

export const ProtectedDoctorRoute: FC<Props> = ({ children }) => {
    const { loading } = useAuth();
    const { user } = useAppSelector((state) => state.userReducer);
    const { pathname, search } = useLocation();

    useEffect(() => {
        const path = pathname + search;
        trackPage(path);
    }, [pathname, search]);

    if (loading) {
        return <Loader width="100%" height="70vh" />;
    }

    if (user.role !== UserRole.DOCTOR) {
        return <Navigate to={RoutesNames.LOGIN} />;
    }

    return <Suspense fallback={<Loader width="100%" height="70vh" />}>{children}</Suspense>;
};
