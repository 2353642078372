import {
    CalendarOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    LaptopOutlined,
    MessageOutlined,
    UserOutlined,
    WalletOutlined
} from "@ant-design/icons";
import { Badge, Menu, MenuProps } from "antd";
import type { MenuItemType } from "antd/es/menu/hooks/useItems";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";

interface Props {
    selectedLink: string[];
}

export const NavbarLink: FC<Props> = ({ selectedLink }) => {
    const { user } = useAppSelector((state) => state.userReducer);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const count = 0;

    const doctorNavLinks: MenuItemType[] = [
        {
            key: "appointments",
            icon: <CalendarOutlined />,
            label: t("Records")
        },
        {
            key: "recipes",
            icon: <FileTextOutlined />,
            label: t("Recipes")
        },
        {
            key: "patients",
            icon: <FileSearchOutlined />,
            label: t("Patients")
        },
        {
            key: "finance",
            icon: <WalletOutlined />,
            label: t("Finance")
        },
        {
            key: "chat",
            icon: <MessageOutlined />,
            label: (
                <ChatMenuItem>
                    {t("Chat")} {count > 0 && <Badge count={count} color="rgba(24, 144, 255, 1)" />}
                </ChatMenuItem>
            )
        },
        {
            key: "consultation",
            icon: <LaptopOutlined />,
            label: t("Online consultations")
        },
        {
            key: "profile",
            icon: <UserOutlined />,
            label: t("Profile")
        }
    ];

    const patientNavLinks: MenuItemType[] = [
        {
            key: "consultation",
            icon: <LaptopOutlined />,
            label: t("Online consultations")
        },
        {
            key: "appointments",
            icon: <CalendarOutlined />,
            label: t("Records")
        },
        {
            key: "recipes",
            icon: <FileTextOutlined />,
            label: t("Recipes")
        },
        {
            key: "disease",
            icon: <FileSearchOutlined />,
            label: t("Medical history")
        },
        {
            key: "finance",
            icon: <WalletOutlined />,
            label: t("Finance")
        },
        {
            key: "chat",
            icon: <MessageOutlined />,
            label: (
                <ChatMenuItem>
                    {t("Chat")} {count > 0 && <Badge count={count} color="rgba(24, 144, 255, 1)" />}
                </ChatMenuItem>
            )
        },
        {
            key: "profile",
            icon: <UserOutlined />,
            label: t("Profile")
        }
    ];

    const onSelectMenuItem: MenuProps["onClick"] = (e) => {
        navigate(`/${user.role}/${e.key}`);
    };

    return (
        <Menu
            mode="inline"
            selectedKeys={selectedLink}
            onSelect={onSelectMenuItem}
            items={user.role === UserRole.DOCTOR ? doctorNavLinks : patientNavLinks}
        />
    );
};

const ChatMenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;
