import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showNotification } from "../helpers/showNotification";
import { useAppDispatch } from "../hooks/redux";
import { UserRole } from "../models/authTypes";
import type { UserResponse } from "../models/userTypes";
import { RoutesNames } from "../routes/enum";
import { publicRoutes } from "../routes/publicRoutes";
import UserService from "../services/UserService";
import { getNotification } from "../store/actions/userActions";
import { userSlice } from "../store/reducers/UserSlice";
import { caching } from "../utils/checkCash";

interface IUserContext {
    currentUser?: UserResponse;
    loading: boolean;
    setCurrentUser: Dispatch<SetStateAction<UserResponse | undefined>>;
}

const AuthContext = createContext<IUserContext>({ loading: true, setCurrentUser: () => {} });

interface Props {
    children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
    const [currentUser, setCurrentUser] = useState<UserResponse>();
    const [loading, setLoading] = useState<boolean>(true);
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const token = new URLSearchParams(search).get("token");

    useEffect(() => {
        caching();
        if (!currentUser) {
            UserService.getUser(token)
                .then(({ data }) => {
                    setCurrentUser(data);
                    dispatch(userSlice.actions.setCurrentUser(data));
                    setLoading(false);

                    if (data.role === UserRole.PATIENT && !data.isFormActual) {
                        showNotification(
                            "Будь ласка заповніть форму оцінки здоров'я",
                            "warning",
                            `Перейдіть до сторінки редагування профілю до вкладки "Оцінка здоров'я"`
                        );
                    }
                })
                .then(() => dispatch(getNotification()))
                .catch((err) => {
                    if (
                        err?.response?.status === 401 &&
                        !publicRoutes.includes(pathname) &&
                        !pathname.includes(RoutesNames.GUEST)
                    ) {
                        navigate(RoutesNames.HOME);
                    }
                    setLoading(false);
                });
        }
        return () => {
            setCurrentUser(undefined);
        };
    }, []);

    const value = {
        currentUser,
        loading,
        setCurrentUser
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
