import { Tabs, TabsProps } from "antd";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";
import { authSlice } from "../../store/reducers/AuthSlice";
import { AppDispatch } from "../../store/store";
import { LoginForm } from "./LoginForm";

interface Props {
    title: string;
    formItems?: ReactNode;
    isRegistration?: boolean;
    step?: number;
    onResetStep?: () => void;
}

export const UserRoleTabs: FC<Props> = ({
    title,
    step = 1,
    isRegistration = false,
    onResetStep = () => {},
    formItems
}) => {
    const { role } = useAppSelector((state) => state.authReducer);
    const dispatch: AppDispatch = useAppDispatch();
    const { t } = useTranslation();

    const items: TabsProps["items"] = [
        {
            key: "patient",
            label: t("I am a patient"),
            children: formItems ?? <LoginForm />
        },
        {
            key: "doctor",
            label: t("I am a doctor"),
            children: formItems ?? <LoginForm />
        }
    ];

    const onChangeUserRole = (key: string) => {
        dispatch(authSlice.actions.changeUserRole(key as UserRole));
        isRegistration && onResetStep();
    };

    const isPatient = role === "patient";

    return (
        <Container show={isRegistration}>
            <img width={140} src="/images/VMS_logo.png" alt="logo" />
            <h1>
                {title}{" "}
                {isRegistration && (
                    <span>{isPatient ? ` ${t("of patient")}` : ` ${t("of doctor")}`}</span>
                )}
            </h1>
            {isRegistration && <span className="steps">{t("Three steps", { step })}</span>}
            <Tabs defaultActiveKey={role} items={items} onChange={onChangeUserRole} />
        </Container>
    );
};

const Container = styled.div<{
    show: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .ant-tabs-nav-wrap {
        display: ${({ show }) => (show ? "flex" : "none")} !important;
    }
`;
