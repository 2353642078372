import { useTranslation } from "react-i18next";
import styled from "styled-components";

const PlatformRules = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <RulesWrapper>
                <h1>Політика конфіденційності </h1>
                <span className="policy-updates">Редакція дійсна з 01.02.2024 р.</span>
                <span className="policy-subtitle">
                    КОЖЕН КОРИСТУВАЧ ПОВИНЕН УВАЖНО ПРОЧИТАТИ ТА ДОТРИМУВАТИСЯ ЦІЄЇ ПОЛІТИКИ
                    КОНФІДЕНЦІЙНОСТІ. ВИКОРИСТОВУЮЧИ ВЕБ-САЙТ, ВИ ПОГОДЖУЄТЕСЯ З УМОВАМИ НАШОЇ
                    ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ. ЯКЩО ВИ НЕ ПОГОДЖУЄТЕСЯ З УМОВАМИ ЦІЄЇ ПОЛІТИКИ
                    КОНФІДЕНЦІЙНОСТІ, УТРИМАЙТЕСЯ ВІД ВИКОРИСТАННЯ НАШОГО ВЕБ-САЙТУ. ВИ ТАКОЖ
                    ПОГОДЖУЄТЕСЯ З ТИМ, ЩО ВАШІ ПЕРСОНАЛЬНІ ДАНІ, ЯКІ МИ ЗБИРАЄМО, ПЕРЕДАЮТЬСЯ З
                    ВАШОГО ЗГОДИ. 
                </span>
                <p className="policy-description">
                    Наша Політика конфіденційності визначає умови конфіденційності при використанні
                    Веб-сайту Virtual medical space https://likarweb.com/  (Веб-сайт) далі за
                    текстом «Веб-сайт», загальні правила збору, обробки, поширення, використання і
                    зберігання персональних даних Користувача власником Веб-сайту.  Ми дотримуємося
                    усіх вимог законодавства щоб захистити вашу конфіденційність. Наша Політика
                    конфіденційності – це юридичний документ, у якому пояснюється, яку інформацію
                    про вас ми будемо збирати, коли ви використовуєте Веб-сайт, як ця інформація
                    буде використовуватися і як ми будемо передавати і захищати цю інформацію. Ми
                    відповідально ставимося до обробки та зберігання персональної інформації
                    Користувачів послуг, а також відвідувачів Веб-сайту https://likarweb.com/
                    <br />
                    <br />
                    Персональна інформація – це будь-яка інформація про особу, яка дозволяє прямо чи
                    опосередковано ідентифікувати цю особу, включаючи її персональні дані, у
                    розумінні законодавства про захист персональних даних. Політика складена
                    відповідно до ст. 6, 8, 10, 11 Закону України «Про захист персональних даних» та
                    визначає порядок обробки персональних даних та заходи по забезпеченню безпеки
                    персональних даних, що вживаються ФОП Петюніна О.В. 
                </p>
                <div className="policy-section">
                    <h3>Мета та процедура збору інформації</h3>
                    <p>
                        Відвідуючи Веб-сайт, ви приймаєте нашу Політику та дозволяєте Оператору
                        збирати, використовувати і розкривати інформацію про вас відповідно до
                        цілей, що вказані нижче.
                        <br />
                        <br />
                        ФОП Петюніна О.В. збирає та обробляє наступну інформацію: ім'я, прізвище та
                        по батькові користувача (клієнта), поштову адресу, адресу електронної пошти
                        та телефон. Ми збираємо лише ті дані, які ви надаєте нам добровільно.
                        Наприклад, ми можемо збирати персональну інформацію (дані вашого
                        загальнодоступного профілю соціальної мережі) для того, щоб отримати від вас
                        відгук про наші послуги, або ми запитуємо інформацію про ваше
                        місцезнаходження для реєстрації на сайті, з метою надання послуг. Крім того,
                        коли ви відвідуєте наш веб-сайт, ми можемо отримувати певну інформацію про
                        вас автоматично. Така інформація може включати: вашу IP-адресу, відомості
                        про операційну систему вашого комп'ютера/мобільного пристрою і тип браузера,
                        тип вашого пристрою, унікальний ідентифікатор пристрою (UDID) або
                        ідентифікатор мобільного обладнання (MEID) вашого мобільного пристрою,
                        адресу сайту, на якому було розміщено посилання про нас та інформацію про
                        сторінки, які ви відвідали на нашому веб-сайті. Для перевірки наданих даних
                        Веб-сайт залишає за собою право вимагати докази ідентичності в онлайн або
                        офлайн режимах.
                        <br />
                        Ми можемо використовувати вказану інформацію для управління, технічного
                        обслуговування та налаштування нашого веб-сайту та сервісів. У цій Політиці
                        конфіденційності ми також надаємо додаткову інформацію про те, як ми
                        використовуємо файли cookie. Погоджуючись із використанням файлів-cookie при
                        першому відвіданні нашого веб-сайту, відповідно до положень Політики, ви
                        надаєте нам дозвіл на використання файлів-cookie при кожному вашому
                        наступному візиті.
                        <br />
                        <br />
                        Веб-сайт використовує особисту інформацію Користувача для обслуговування та
                        покращення якості наданих послуг. Частина персональної інформації може бути
                        надана банку або платіжній системі, якщо надання цієї інформації обумовлено
                        процедурою переказу коштів платіжної системи, послугами якої Користувач
                        бажає скористатися. Веб - сайт докладає всіх зусиль для збереження особистих
                        даних Користувача. Особиста інформація може бути розкрита у випадках,
                        описаних законодавством, або коли адміністрація визнає такі дії необхідними
                        для дотримання юридичної процедури, судового розпорядження або легального
                        процесу, необхідного для роботи Користувача з Веб-сайтом. В інших випадках,
                        ні за яких умов інформація, яку Користувач передає Веб-сайту, не буде
                        розкрита третім особам. 
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Захист даних</h3>
                    <p>
                        Адміністрація Веб-сайту не може передавати або приховувати інформацію,
                        надану Користувачем (далі – Користувач) під час реєстрації та використання
                        функцій веб-сайту третім особам, крім випадків, описаних законодавством
                        країни, на території якої Користувач веде свою діяльність. Веб- сайт
                        забезпечує безпеку облікового запису Користувача від несанкціонованого
                        доступу.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Комунікація</h3>
                    <p>
                        Після того як Користувач залишив дані, він отримує повідомлення, яке
                        підтверджує його успішну реєстрацію. Користувач має право будь-якої миті
                        припинити отримання інформаційних бюлетенів.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Внесення змін до Політики конфіденційності</h3>
                    <p>
                        Веб-сайт залишає за собою право вносити зміни до Політики конфіденційності
                        без додаткових повідомлень. Нововведення набирають чинності з їх
                        опублікування. Користувачі можуть самостійно відстежувати зміни в Політиці
                        конфіденційності. Ми маємо право в будь-який час вносити зміни до цієї
                        політики, розмістивши нову редакцію політики конфіденційності на цій
                        сторінці https://likarweb.com/service_rules. Ми наполегливо рекомендуємо
                        часто перевіряти цю сторінку та дату внесення останніх змін, зазначену у
                        верхній частині сторінки, щоб бути в курсі останніх змін.
                        <br />
                        <br />У разі незгоди з будь-якими змінами, внесеними до Політики, ви повинні
                        припинити користуватися сайтом та послугами Оператора і можете вимагати, щоб
                        ми видалили вашу персональну інформацію. Ця політика конфіденційності
                        поширюється на всю персональну інформацію користувачів
                        сайту https://likarweb.com/ 
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Видалення даних</h3>
                    <p>
                        Користувач має право вимагати виправлення або видалення своїх особистих
                        даних, відправивши нам листа електронною поштою за адресою
                        support@likarweb.com.
                        <br />
                        <br />
                        Ви можете в будь-який момент відкликати свою згоду на обробку Персональних
                        даних. Якщо ви відкличете свою згоду на обробку Персональних даних і у нас
                        не буде законних підстав для продовження обробки Ваших даних, ми припинимо
                        обробку ваших Персональних даних. Якщо у нас будуть законні підстави для
                        обробки Ваших даних, ми маємо право продовжувати використовувати Ваші
                        Персональні дані в межах, передбачених законом. Зверніть увагу, що ми можемо
                        зберегти частину або всі дані Користувача і не видаляти їх до тих пір, поки
                        не будуть виконані всі можливі вимоги, пов’язані з Персональними даними
                        Користувача (наприклад, щодо надання послуг, запобігання шахрайства,
                        дотримання будь-яких юридичних зобов’язань, вирішення суперечок, виконання
                        угод тощо). У разі, якщо ми видалимо будь-які дані, створені Користувачем,
                        включаючи Персональні дані, такі дані буде видалено з активної бази даних,
                        але вони можуть залишитися у наших архівах. Заархівована версія даних не
                        призначена для використання в якості резервної копії та недоступна для
                        будь-якого Користувача.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Cookie-файли, веб-маяки та інші технології</h3>
                    <p>
                        Веб-сайт  https://likarweb.com/ збирає інформацію про вашу поведінку на
                        нашому сайті та відвідування сторінок сайту, з метою отримання статистики
                        відвідувань та ефективності використання сайту, формування персонального
                        підходу та адаптації наших сервісів до інтересів кожного користувача. Ми
                        робимо це за допомогою різних технологій, одна з яких – cookie-файли. Це
                        дані, які веб-сайт може надіслати вашому браузеру, і вони будуть збережені
                        на вашому комп'ютері для подальшої ідентифікації його веб-сайтом. Найчастіше
                        cookie-файли використовуються для оцінки ефективності роботи веб-сайтів
                        (наприклад, для вимірювання кількості відвідувачів та тривалості їх
                        перебування на сайті), виявлення найбільш цікавих та відвідуваних тем та
                        сторінок, а також для забезпечення зручності навігації та користування
                        сайтом, без збору якої - або персональної інформації. Також cookie-файли
                        можуть бути використані для формування персонального підходу на основі вже
                        наявного досвіду взаємодії користувача з сайтом та його перевагами. З часом
                        ця інформація дозволяє покращити користувальницький досвід.
                        <br />
                        <br />
                        Якщо ви не бажаєте більше отримувати файли cookie або якщо ви хочете щоб вас
                        інформували про отримання cookie, налаштуйте ваш веб-браузер. Однак, якщо ви
                        відключите всі файли cookie, ви не зможете скористатися деякими сервісами та
                        функціями сайту.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Забезпечення інформаційної безпеки дітей</h3>
                    <p>
                        Веб-сайту https://likarweb.com/ не орієнтовані на привернення уваги дітей та
                        неповнолітніх. Оператор навмисно не займається збором персональних даних
                        дітей та осіб віком до 18 років, і не надсилає їм жодних запитів на надання
                        персональної інформації.
                    </p>
                </div>
                <div className="policy-section">
                    <h3>Прикінцеві положення</h3>
                    <p>
                        У разі незгоди з будь-якими змінами, внесеними до Політики, ви повинні
                        припинити користуватися сайтом та послугами Оператора і можете вимагати, щоб
                        ми видалили вашу персональну інформацію.
                        <br />
                        Політика конфіденційності поширюється на всю персональну інформацію
                        користувачів Веб-сайту https://likarweb.com/, яку ми маємо, незалежно від
                        методу збору та способу її отримання.
                        <br />
                        Дана Політика конфіденційності відповідає вимогам законодавства України про
                        захист персональних даних, Політика конфіденційності підготовлена ​​для
                        виконання обов'язків, передбачених Загальним регламентом захисту даних
                        (Регламент ЄС 2016/679 від 27 квітня 2016 р. або GDPR — General Data
                        Protection Regulation). Користувач може отримати будь-які роз’яснення по
                        питаннях, що стосуються обробки його персональних даних, звернувшись до
                        Оператора за допомогою електронної пошти support@likarweb.com.
                        <br />В даному документі будуть відображені будь-які зміни політики обробки
                        персональних даних Оператором. Політика діє безстроково до заміни її новою
                        версією.
                    </p>
                </div>
                <div className="policy-section">
                    <h2>Наші контакти</h2>
                    <a href="tel:+380933893040">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.49805 10.249L10.4247 12.0534C11.3905 12.6488 12.6094 12.6488 13.5751 12.0534L16.5018 10.249"
                                stroke="#1677FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <rect
                                x="2.99609"
                                y="5.49707"
                                width="18.0075"
                                height="14.0058"
                                rx="3"
                                stroke="#1677FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        +380933893040
                    </a>
                    <a href="mailto:support@likarweb.com">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.498 3.49609V5.29684C9.47304 5.59989 9.69563 5.867 9.99821 5.89709H13.9999C14.3025 5.867 14.5251 5.59989 14.5001 5.29684V3.49609"
                                stroke="#1677FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M9.99805 18.5022H13.9997"
                                stroke="#1677FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.0003 21.5036H7.99693C6.8919 21.5036 5.99609 20.6078 5.99609 19.5028V5.49693C5.99609 4.3919 6.8919 3.49609 7.99693 3.49609H16.0003C17.1053 3.49609 18.0011 4.3919 18.0011 5.49693V19.5028C18.0011 20.6078 17.1053 21.5036 16.0003 21.5036Z"
                                stroke="#1677FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        support@likarweb.com
                    </a>
                </div>
            </RulesWrapper>
        </Container>
    );
};

export default PlatformRules;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RulesWrapper = styled.div`
    padding: 25px;
    display: flex;
    max-width: 960px;
    flex-direction: column;
    width: 100%;
    border-radius: 22px;
    background-color: #fff;
    font-family: "Raleway";
    gap: 25px;
    margin: 25px 0;

    @media (max-width: 490px) {
        padding: 10px;
    }

    & > h1 {
        font-size: 50px;
        font-weight: 800;
        line-height: 65px;
        color: #212121;
        margin-top: 25px;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 40px;
            line-height: 52px;
        }

        @media (max-width: 490px) {
            padding: 0 20px;
            font-size: 36px;
            line-height: 46.8px;
        }
    }

    .policy-updates {
        font-weight: 600;
        font-size: 18px;
        font-family: "Roboto";
        line-height: 25.2px;
        color: #2d2d2d;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 16px;
            line-height: 22.4px;
        }
    }

    .policy-subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        font-family: "Roboto";
        color: #8c8c8c;

        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 18px;
            line-height: 27px;
        }
    }

    .policy-description {
        @media (max-width: 821px) {
            padding: 0 20px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    p,
    a {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #212121;
        font-family: "Roboto";
        display: flex;
        gap: 5px;

        @media (max-width: 821px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .policy-section {
        h3 {
            font-size: 34px;
            font-weight: 700;
            line-height: 44.2px;
            font-family: "Raleway";
            color: #212121;
            margin-bottom: 12px;

            @media (max-width: 821px) {
                font-size: 32px;
                line-height: 41.6px;
            }
        }

        h2 {
            font-weight: 700;
            font-size: 34px;
            line-height: 44.2px;
            font-family: "Raleway";
            margin-bottom: 12px;

            @media (max-width: 821px) {
                font-size: 32px;
                line-height: 41.6px;
            }
        }

        @media (max-width: 821px) {
            padding: 0 20px;
        }
    }
`;
