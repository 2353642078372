import { Button } from "antd";
import { FC, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

interface Props {
    width?: string;
    size?: "large" | "middle" | "small";
    type?: "primary" | "dashed" | "default" | "link" | "text";
    htmlType?: "button" | "submit" | "reset";
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    marginTop?: number;
    align?: "center" | "flex-end" | "flex-start";
    onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
}

const CButton: FC<Props> = ({
    width = "100%",
    disabled,
    htmlType,
    type = "primary",
    size = "large",
    className,
    children,
    align = "flex-end",
    loading = false,
    marginTop = 0,
    onClick
}) => {
    return (
        <ButtonContainer align={align} marginTop={marginTop} width={width} className={className}>
            <Button
                onClick={onClick}
                size={size}
                loading={loading}
                type={type}
                htmlType={htmlType}
                disabled={disabled}
            >
                {children}
            </Button>
        </ButtonContainer>
    );
};

export default CButton;

const ButtonContainer = styled.div<{
    width: string;
    align: string;
    marginTop: number;
}>`
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align};
    margin-top: ${({ marginTop }) => marginTop + "px"};
    button {
        width: ${({ width }) => width};
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-weight: 500;
            font-size: 16px;
        }
    }
`;
