import { CloseOutlined } from "@ant-design/icons";
import { notification } from "antd";
import type { ArgsProps } from "antd/es/notification/interface";

type NotificationType = "success" | "error" | "warning" | "info";

export const showNotification = (message: string, type: NotificationType, description?: string) => {
    const config: ArgsProps = {
        message,
        description,
        placement: "topRight",
        closeIcon: <CloseOutlined />,
        duration: 5
    };

    notification[type](config);
};
