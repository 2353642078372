import type { RcFile } from "antd/es/upload";
import type { IUserProfile } from "../../models/userTypes";
import UserService from "../../services/UserService";
import { userSlice } from "../reducers/UserSlice";
import { AppDispatch } from "../store";

export const saveProfileData = (profile: IUserProfile) => async (dispatch: AppDispatch) => {
    try {
        dispatch(userSlice.actions.setIsLoading(true));
        const { data } = await UserService.updateUser(profile);
        dispatch(userSlice.actions.setCurrentUser(data));
    } catch (error) {
        dispatch(userSlice.actions.setError("Помилка збереження даних профіля"));
        throw error;
    }
};

export const uploadFile =
    (name: "original" | "avatar" | "public", file: File) => async (dispatch: AppDispatch) => {
        try {
            dispatch(userSlice.actions.setIsLoading(true));
            const formData = new FormData();
            formData.append(name, file);
            const { data } = await UserService.uploadMedia(formData);
            dispatch(userSlice.actions.setCurrentUser(data));
            return {
                original: data.originalUrl ?? "",
                avatar: data.avatarUrl ?? "",
                publicImg: data.publicUrl ?? ""
            };
        } catch (error) {
            dispatch(userSlice.actions.setError("Помилка збереження файлу"));
            throw error;
        }
    };

export const uploadFiles = async (files: RcFile[]) => {
    try {
        const formData = new FormData();
        formData.append("files", JSON.stringify(files));
        await UserService.uploadMedia(formData);
    } catch (error) {
        throw error;
    }
};

export const getNotification = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await UserService.getNotification();
        dispatch(userSlice.actions.setNotification(data));
    } catch (error) {
        throw error;
    }
};

export const removeNotification = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await UserService.removeNotification(id);
        dispatch(userSlice.actions.setNotification(data));
    } catch (error) {
        throw error;
    }
};

export const removeAllNotifications = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await UserService.removeAllNotifications();
        dispatch(userSlice.actions.setNotification(data));
    } catch (error) {
        throw error;
    }
};
