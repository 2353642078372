export const CheckCircleGreen = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_872_94084)">
                <path
                    d="M11.3392 5.23145H10.5017C10.3196 5.23145 10.1464 5.31895 10.0392 5.46895L7.23209 9.3618L5.96066 7.59752C5.85352 7.4493 5.68209 7.36002 5.49816 7.36002H4.66066C4.54459 7.36002 4.47673 7.49216 4.54459 7.5868L6.76959 10.6725C6.82215 10.7459 6.89145 10.8057 6.97172 10.8469C7.052 10.8881 7.14095 10.9096 7.2312 10.9096C7.32145 10.9096 7.4104 10.8881 7.49067 10.8469C7.57095 10.8057 7.64024 10.7459 7.6928 10.6725L11.4535 5.45823C11.5232 5.36359 11.4553 5.23145 11.3392 5.23145Z"
                    fill="#52C41A"
                />
                <path
                    d="M8 0.0712891C3.58214 0.0712891 0 3.65343 0 8.07129C0 12.4891 3.58214 16.0713 8 16.0713C12.4179 16.0713 16 12.4891 16 8.07129C16 3.65343 12.4179 0.0712891 8 0.0712891ZM8 14.7141C4.33214 14.7141 1.35714 11.7391 1.35714 8.07129C1.35714 4.40343 4.33214 1.42843 8 1.42843C11.6679 1.42843 14.6429 4.40343 14.6429 8.07129C14.6429 11.7391 11.6679 14.7141 8 14.7141Z"
                    fill="#52C41A"
                />
            </g>
            <defs>
                <clipPath id="clip0_872_94084">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.0712891)" />
                </clipPath>
            </defs>
        </svg>
    );
};
