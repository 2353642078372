import { Button } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SVGIcon } from "../SVG";

const MobileAlert = () => {
    const { t } = useTranslation();

    const onOpenMobileApp = () => {
        // Check for Android
        if (/Android/i.test(navigator.userAgent)) {
            alert("User is using Android");
        }

        // Check for iOS (iPhone/iPad)
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            alert("User is using iOS");
        }

        // Check for Windows Phone
        if (/Windows Phone/i.test(navigator.userAgent)) {
            alert("User is using Windows Phone");
        }
    };

    return (
        <Container>
            <div className="mobile-alert">
                <SVGIcon type="logo" />
                <div className="mobile-alert-title">
                    <h1>Virtual Medical Space</h1>
                    <span>{t("Open in VMS application")}</span>
                </div>
            </div>
            <Button type="primary" onClick={onOpenMobileApp}>
                {t("Open")}
            </Button>
        </Container>
    );
};

export default MobileAlert;

const Container = styled.div`
    width: 100%;
    height: 88px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        color: var(--character-primary-inverse, #fff);
        text-align: center;
        font-family: "Roboto";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    .mobile-alert {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            width: 50px;
            height: 50px;
        }

        &-title {
            display: flex;
            flex-direction: column;

            h1 {
                color: var(--character-title-85, rgba(0, 0, 0, 0.85));
                font-family: "Roboto";
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0 !important;
            }

            span {
                color: var(--weak, #999);
                font-family: "Roboto";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`;
