import { Input } from "antd";
import { ChangeEvent, FC } from "react";
import styled from "styled-components";

interface Props {
    label?: string;
    value: string;
    name?: string;
    maxLength?: number;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const CTextarea: FC<Props> = ({ label, value, name, onChange, maxLength = 100 }) => {
    return (
        <TextareaContainer>
            {label && <label htmlFor="custom_textarea">{label}</label>}
            <Input.TextArea
                id="custom_textarea"
                showCount
                rows={3}
                name={name}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
            />
        </TextareaContainer>
    );
};

export default CTextarea;

const TextareaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }
`;
