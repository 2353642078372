import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { FC } from "react";
import clsx from "clsx";

interface Props {
    data: Array<{ id: number; title: string; subtitle: string; imgSrc?: string }>;
}

const SecondSection: FC<Props> = ({ data }) => {
    const { t } = useTranslation();

    const variants = {
        visible: { opacity: 1, scale: 1, y: 0 },
        hidden: {
            opacity: 0,
            scale: 0.65,
            y: 50
        }
    };

    return (
        <SecondSectionContainer>
            <div className="second-section-title">
                <h1>{t("Advantages of our service")}</h1>
                <span>
                    {t(
                        "Our online medical service is a reliable support and care for your health. We strive to make medical care more accessible, convenient and effective for all our patients. Here are just some of the benefits you will get by using our service:"
                    )}
                </span>
            </div>
            <div className="grid-advantages">
                {data.map((item, idx) => (
                    <InView key={item.id}>
                        {({ inView, ref }) => (
                            <motion.div
                                id={`item-${idx}`}
                                animate={inView ? "visible" : "hidden"}
                                variants={variants}
                                transition={{ duration: 0.7, ease: "easeOut" }}
                                ref={ref}
                            >
                                <h1>{item.title}</h1>
                                <span
                                    className={clsx(
                                        (idx === 0 || idx === 3 || idx === 4) && "grey-title"
                                    )}
                                >
                                    {item.subtitle}
                                </span>
                                {item.imgSrc && (
                                    <img src={item.imgSrc} alt={`illustration_${idx + 1}`} />
                                )}
                            </motion.div>
                        )}
                    </InView>
                ))}
            </div>
        </SecondSectionContainer>
    );
};

export default SecondSection;

const SecondSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;

    .second-section-title {
        max-width: 730px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        text-align: center;

        h1 {
            color: #212121;
            font-family: "Raleway";
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        span {
            color: #787878;
            text-align: center;
            font-family: "Roboto";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

    .grid-advantages {
        width: 100%;
        max-width: 1140px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        height: 100%;

        & > div {
            height: 400px;
            border-radius: 30px;
            padding: 50px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;

            h1 {
                font-family: Raleway;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 40.8px */
            }
            span {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
                max-width: 380px;
            }

            .grey-title {
                color: #787878;
            }

            img {
                position: absolute;
                width: 50%;
                max-width: 310px;
                bottom: 0;
                right: 30px;
            }
        }

        #item-0 {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 3;
            background: #f0f6ff;
        }
        #item-1 {
            grid-row-start: 1;
            grid-column-start: 3;
            grid-row-end: 2;
            grid-column-end: 4;
            background: #1677ff;
            color: #fff;
        }
        #item-2 {
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;
            background: #1677ff;
            color: #fff;
        }
        #item-3 {
            grid-row-start: 2;
            grid-column-start: 2;
            grid-row-end: 3;
            grid-column-end: 4;
            background: #f0f6ff;
        }
        #item-4 {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 3;
            background: #f0f6ff;
        }
        #item-5 {
            grid-row-start: 3;
            grid-column-start: 3;
            grid-row-end: 4;
            grid-column-end: 4;
            background: #1677ff;
            color: #fff;
        }
    }

    @media (max-width: 770px) {
        .second-section-title {
            max-width: 75%;
            h1 {
                font-size: 28px;
            }

            span {
                font-size: 14px;
            }
        }

        .grid-advantages {
            width: 90%;
            max-width: auto;
            gap: 20px;

            & > div {
                height: auto;
                border-radius: 30px;
                padding: 50px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                position: relative;

                h1 {
                    font-size: 20px;
                }
                span {
                    font-size: 14px;
                }

                .grey-title {
                    color: #787878;
                }

                img {
                    position: absolute;
                    width: 50%;
                    max-width: 310px;
                    bottom: 0;
                    right: 30px;
                }
            }

            #item-0,
            #item-3,
            #item-4 {
                height: 250px;
            }
        }
    }

    @media (max-width: 450px) {
        margin-top: 0px;

        .second-section-title {
            max-width: 85%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;
            text-align: center;

            h1 {
                font-size: 24px;
            }

            span {
                font-size: 13px;
            }
        }

        .grid-advantages {
            width: 90%;
            max-width: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > div {
                height: auto;
                border-radius: 30px;
                padding: 50px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                position: relative;

                h1 {
                    font-size: 18px;
                }
                span {
                    font-size: 13px;
                }

                .grey-title {
                    color: #787878;
                }

                img {
                    position: absolute;
                    width: 50%;
                    max-width: 310px;
                    bottom: 0;
                    right: 30px;
                }
            }

            #item-0,
            #item-3,
            #item-4 {
                height: 250px;
            }
        }
    }
`;
