import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Drawer, Grid } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";
import { RoutesNames } from "../../routes/enum";
import { authSlice } from "../../store/reducers/AuthSlice";
import { Language } from "../RootLayout/Language";
import { SVGIcon } from "../SVG";

const Navbar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { lg, sm } = Grid.useBreakpoint();
    const { role } = useAppSelector((state) => state.authReducer);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const goToLogin = () => navigate(RoutesNames.LOGIN);
    const goToRegistration = () => navigate(`${RoutesNames.LOGIN}/${RoutesNames.REGISTRATION}`);
    const toggleOpenMenu = () => setOpenMenu((prev) => !prev);

    const onChangeRole = (role: UserRole) => () => {
        dispatch(authSlice.actions.setUserRole(role));
        setOpenMenu(false);
    };

    return (
        <>
            <Container>
                <div className="left-section">
                    {sm ? (
                        <img className="logo" src="/images/home-logo.png" alt="logo" />
                    ) : (
                        <SVGIcon type="logo" />
                    )}
                    {lg && (
                        <div className="actions">
                            <Button
                                style={{ color: role === UserRole.PATIENT ? "#1677FF" : "" }}
                                type="text"
                                onClick={onChangeRole(UserRole.PATIENT)}
                            >
                                {t("For patients")}
                            </Button>
                            <Button
                                style={{ color: role === UserRole.DOCTOR ? "#1677FF" : "" }}
                                type="text"
                                onClick={onChangeRole(UserRole.DOCTOR)}
                            >
                                {t("For doctors")}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="right-section">
                    <Button type="text" onClick={goToLogin}>
                        {t("Log in")}
                    </Button>
                    <Button type="primary" onClick={goToRegistration}>
                        {t("Sign up")}
                    </Button>
                    {!lg && (
                        <Button type="text" onClick={toggleOpenMenu}>
                            <MenuOutlined />
                        </Button>
                    )}
                    {lg && <Language />}
                </div>
            </Container>
            <Drawer
                title={<CloseOutlined onClick={toggleOpenMenu} />}
                headerStyle={{ textAlign: "end" }}
                placement="top"
                closable={false}
                onClose={toggleOpenMenu}
                open={openMenu}
                bodyStyle={{ padding: "0 15px" }}
                style={{ height: "250px" }}
                contentWrapperStyle={{ height: "250px" }}
            >
                <DrawerContent>
                    <Button
                        style={{ color: role === UserRole.PATIENT ? "#1677FF" : "" }}
                        type="text"
                        onClick={onChangeRole(UserRole.PATIENT)}
                    >
                        {t("For patients")}
                    </Button>
                    <Button
                        style={{ color: role === UserRole.DOCTOR ? "#1677FF" : "" }}
                        type="text"
                        onClick={onChangeRole(UserRole.DOCTOR)}
                    >
                        {t("For doctors")}
                    </Button>
                    <Language />
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default Navbar;

const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid #d2d2d2;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 15;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);

    .actions {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .left-section,
    .right-section {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 0 20px;

        .logo {
            max-width: 270px;
            width: 100%;
        }

        svg {
            width: 50px;
        }

        @media (max-width: 450px) {
            gap: 0;
            padding: 0;
        }
    }

    button {
        & > span {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    @media (max-width: 450px) {
        width: 100vw;
    }
`;

const DrawerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px;

    span {
        font-weight: 700;
    }
`;
