import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
    DoctorDetailResponse,
    IDoctorCard,
    IPagination
} from "../../models/consultationsTypes";
import {
    type IRecipeMedicamentState,
    type IRecipeResponse,
    RecipeStatus
} from "../../models/recipeTypes";

interface RecipeState {
    isLoading: boolean;
    doctorRecipes: {
        requests: IRecipeResponse[];
        completed: IRecipeResponse[];
    };
    patientRecipes: IRecipeResponse[];
    recipeDetails: IRecipeResponse;
    consultationRecipe: Array<IRecipeMedicamentState>;
    pagination: {
        skip: number;
        take: number;
    };
    doctorList: Array<IDoctorCard>;
    doctorDetail: DoctorDetailResponse;
    isNewPrescription: boolean;
    total: number;
}

const initialState: RecipeState = {
    isLoading: false,
    doctorRecipes: {
        requests: [],
        completed: []
    },
    patientRecipes: [],
    recipeDetails: {
        price: 0,
        chronic_diseases: "",
        end_date: "",
        country: "",
        status: RecipeStatus.DONE,
        elements: [],
        consultation_id: "",
        doctor_id: "",
        patient_id: "",
        id: "",
        recipleNumber: 0,
        createdAt: "",
        updatedAt: "",
        doctor: {
            firstName: "",
            id: "",
            lastName: "",
            specializations: [],
            surName: ""
        },
        patient: {
            birthdate: "",
            email: "",
            firstName: "",
            id: "",
            lastName: "",
            phone: "",
            surName: ""
        }
    },
    consultationRecipe: [],
    total: 0,
    pagination: {
        skip: 0,
        take: 10
    },
    doctorList: [],
    doctorDetail: {
        about: "",
        docs: [],
        education: "",
        firstName: "",
        prices: {
            consultation_price: 0,
            recipe_price: 0,
            season_price: 0,
            consultation_length: 40,
            rest_length: 10
        },
        id: "",
        languages: [],
        lastName: "",
        publicUrl: "",
        specialization: [],
        surName: "",
        work_bio: "",
        location_country: ""
    },
    isNewPrescription: false
};

export const recipeSlice = createSlice({
    name: "recipes",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setNewPrescription(state, action: PayloadAction<boolean>) {
            state.isNewPrescription = action.payload;
        },
        setNewDoctorRecipes(state, action: PayloadAction<Array<IRecipeResponse>>) {
            state.isLoading = false;
            state.doctorRecipes.completed = action.payload;
        },
        setDoctorRecipes(
            state,
            action: PayloadAction<{
                recipes: Array<IRecipeResponse>;
                requests: Array<IRecipeResponse>;
            }>
        ) {
            const completed = action.payload.recipes.filter(
                (recipe) => recipe.status === RecipeStatus.DONE
            );
            state.isLoading = false;
            state.doctorRecipes = {
                requests: action.payload.requests,
                completed
            };
        },
        setPatientRecipes(state, action: PayloadAction<IRecipeResponse[]>) {
            state.isLoading = false;
            state.patientRecipes = action.payload;
        },
        setConsultationRecipe(state, action: PayloadAction<Array<IRecipeMedicamentState>>) {
            state.isLoading = false;
            state.consultationRecipe = action.payload;
        },
        setRecipeDetails(state, action: PayloadAction<IRecipeResponse>) {
            state.isLoading = false;
            state.recipeDetails = action.payload;
        },
        setDoctorList(state, action: PayloadAction<Array<IDoctorCard>>) {
            state.isLoading = false;
            state.doctorList = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setDoctorDetails(state, action: PayloadAction<DoctorDetailResponse>) {
            state.isLoading = false;
            state.doctorDetail = action.payload;
        }
    }
});

export default recipeSlice.reducer;
