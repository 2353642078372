import { useKeenSlider } from "keen-slider/react";
import { FC, useEffect, useState } from "react";
import "keen-slider/keen-slider.min.css";

interface Props {
    inView: boolean;
    data: Array<{
        id: number;
        title: string;
        description: string;
        imageSrc: string;
        delay: number;
    }>;
}

const SliderView: FC<Props> = ({ inView, data }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        vertical: true,
        slides: { perView: "auto" },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        }
    });

    useEffect(() => {
        const timeout = setInterval(() => {
            inView && instanceRef.current?.next();
        }, 2000);

        return () => {
            clearInterval(timeout);
        };
    }, [inView]);

    return (
        <div className="slider-section">
            {instanceRef?.current && (
                <div className="slider-dots">
                    {[...Array(data.length)].map((_, idx) => {
                        return (
                            <button
                                key={`${idx}`}
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx);
                                }}
                                className={"slider-dot" + (currentSlide === idx ? " active" : "")}
                            ></button>
                        );
                    })}
                </div>
            )}
            <div ref={sliderRef} className="keen-slider">
                {data.map((slide) => (
                    <img
                        key={slide.id}
                        className="keen-slider__slide"
                        src={slide.imageSrc}
                        alt={`slide-${slide.id}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default SliderView;
