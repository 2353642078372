export enum SendOtpSource {
    REGISTER = "register",
    RESET_PASSWORD = "reset_password"
}

export enum AuthRoutes {
    REGISTER = "/v1/auth/register",
    VERIFY = "/v1/auth/verify",
    LOGIN = "/v1/auth/login",
    REFRESH = "/v1/auth/refresh",
    SEND_OPT = "/v1/auth/send-otp",
    CHANGE_PASSWORD = "/v1/auth/change-password",
    RESET_PASSWORD = "/v1/auth/reset-password"
}

export enum UserRoutes {
    PROFILE = "/v1/user/my-profile",
    UPLOAD = "/v1/upload/avatar",
    PUBLIC_PROFILE = "/v1/user/profile",
    NOTIFICATION = "/v1/notification"
}

export enum PatientRoutes {
    PERSONS = "/v1/user/my-persons",
    ADDRESSES = "/v1/user/my-addresses",
    POLICIES = "/v1/user/my-policies",
    CONSULTATION = "/v1/consultation/doctors",
    DOCTOR_DETAILS = "/v1/consultation/doctor",
    HEALTH_FORM = "/v1/consultation/health-form",
    DASS_FORM = "/v1/consultation/daas-form",
    MEDICAL_CARD = "/v1/medical-card",
    CREATE_PROMO = "/v1/purchase/season",
    RECIPE_PURCHASE = "/v1/purchase/recipe",
    PROMO = "/v1/promo"
}

export enum DoctorRoutes {
    SPECIALIZATION = "/v1/user/my-specialization",
    DOCTOR_INFO = "/v1/user/doctor-info",
    DOCUMENTS = "/v1/user/documents",
    WORK_TIME = "/v1/doctor-work-time",
    VACATION = "/v1/doctor-work-time/vacation",
    BREAK = "/v1/doctor-work-time/additional-break",
    PRICE_CONFIG = "/v1/doctor-config",
    OBSERVATION = "/v1/observation"
}

export enum RecordsRoutes {
    RECORDS = "/v1/consultation",
    REFERRAL = "/v1/referral",
    THERAPY = "/v1/therapy",
    HISTORY = "/v1/history",
    PATIENTS = "/v1/medical-card",
    RECIPES = "/v1/reciple",
    RECIPE_REQUEST = "/v1/reciple/request"
}

export enum ChatRoutes {
    LIST = "/v1/chat/list",
    MESSAGES = "/v1/chat/messages",
    USER = "/v1/chat/user"
}
