import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../../routes/enum";
import { CButton } from "../../common";

interface Props {
    title: string;
    subtitle: string;
    btnText: string;
}

const NinthSection: FC<Props> = ({ title, subtitle, btnText }) => {
    const navigate = useNavigate();

    const goToRegistration = () => {
        navigate(`${RoutesNames.LOGIN}/${RoutesNames.REGISTRATION}`);
    };

    return (
        <Container>
            <div className="section-content">
                <img width={140} src="/images/VMS_logo.png" alt="logo" />
                <h1>
                    {title} <span>VMS</span>
                </h1>
                <span>{subtitle}</span>
                <CButton width="220px" align="center" onClick={goToRegistration}>
                    {btnText}
                </CButton>
            </div>
        </Container>
    );
};

export default NinthSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #001529;
    min-height: 620px;
    background-image: url("/images/section_background.png");
    background-repeat: no-repeat;
    background-size: contain;

    .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (max-width: 770px) {
            width: 90%;
        }

        h1 {
            color: #fff;
            font-family: "Raleway";
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;

            span {
                color: #4ce033;
                font-weight: 700;
            }

            @media (max-width: 770px) {
                font-size: 28px;
            }

            @media (max-width: 450px) {
                font-size: 24px;
            }
        }

        & > span {
            color: #fff;
            font-family: "Roboto";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
            margin-bottom: 30px;
            text-align: center;

            @media (max-width: 770px) {
                font-size: 14px;
            }

            @media (max-width: 450px) {
                font-size: 13px;
            }
        }
    }
`;
