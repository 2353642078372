import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import TranslationEN from "../locales/en/translation.json";
import TranslationUA from "../locales/ua/translation.json";

const fallbackLng = ["ua"];

export type TranslationJSON = typeof TranslationUA;

const resources = {
    en: {
        translation: TranslationEN
    },
    ua: {
        translation: TranslationUA
    }
};

const detectionOptions = {
    order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag"],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "editor_language",
    lookupSessionStorage: "editor_language",

    // cache user language
    caches: ["localStorage"],
    excludeCacheFor: ["cimode"]
};
const savedLang = localStorage.getItem("editor_language");
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: detectionOptions,
        returnEmptyString: false,
        resources,
        lng: savedLang ?? "ua",
        nsSeparator: false,
        keySeparator: false
    });

i18n.init({
    interpolation: {
        format: (value: any) => value
    },
    fallbackLng
});

const translate = i18n.t.bind(i18n);

export { i18n, translate };
