import type { IObservationResponse, IPagination } from "./consultationsTypes";
import type { IAddress, IDassForm, IHealthForm, IPerson, IPolicy } from "./patientTypes";
import type { IRecipeMedicamentState, RecipeStatus } from "./recipeTypes";
import type { UserResponse } from "./userTypes";

export enum ActionsKey {
    TESTS = "tests",
    OBSERVATION = "observation",
    RECEIPTS = "receipts",
    THERAPY = "therapy",
    REFERRAL = "referral"
}

export enum ConsultationStatus {
    FUTURE = "future",
    SOON = "soon",
    IN_PROCESS = "in process",
    FINISHED = "finished",
    CANCELED = "canceled"
}

export interface RecordFilters {
    status: string;
    take: number;
    skip: number;
    from?: string;
    to?: string;
    date?: string;
}

interface IUserInfo {
    firstName: string;
    id: string;
    lastName: string;
    publicUrl?: string;
    avatarUrl?: string;
    specializations?: Array<string>;
    surName: string;
    work_bio?: string;
    prices: {
        consultation_price: number;
        recipe_price: number;
        season_price: number;
    };
}

export type IDocument = {
    consultation_id?: string;
    createdAt: string;
    id: string;
    updatedAt: string;
    url: string;
    filename: string;
    filesize: number;
    filetype: "photo" | "video";
};

export interface IRecords {
    comment: string;
    createdAt: string;
    date: string;
    doctor_id: string;
    documents: Array<IDocument>;
    end: string;
    id: string;
    isFree: boolean;
    isPromo: boolean;
    isSeason: boolean;
    patient_id: string;
    price: number;
    promo: null;
    season: null;
    start: string;
    status: ConsultationStatus;
    type: "text chat" | "video chat";
    updatedAt: string;
    user_info: IUserInfo;
}

export interface ITimeSpan {
    time: string;
    status: ConsultationStatus;
    id: string;
}

export interface IPatientDetails extends UserResponse {
    trustedPersons: Array<IPerson>;
    policies: Array<IPolicy>;
    addresses: Array<IAddress>;
    healthForm: IHealthForm;
    dassForm: Array<IDassForm>;
    referrals: Array<IReferralResponse>;
    therapy: Array<ITherapyResponse>;
}

export interface IConsultationResultState {
    date: string;
    start: string;
    end: string;
    status: ConsultationStatus;
    price: number;
    documents: Array<IDocument>;
    referrals: Array<IReferralResponse>;
    therapy: Array<ITherapyResponse>;
    doctor: {
        firstName: string;
        id: string;
        lastName: string;
        surName: string;
        work_bio: string;
        publicUrl: string;
        specialization: Array<string>;
    };
    recipe: {
        id: string;
        recipleNumber: number;
        status: RecipeStatus;
        elements: Array<IRecipeMedicamentState>;
    };
}

export interface IComment {
    comment: string;
    consultation_id: string;
    createdAt: string;
    id: string;
    updatedAt: string;
}

export interface IObservationConsultation {
    observation: IObservationResponse;
    consultation: {
        date: string;
        status: ConsultationStatus;
        documents: Array<IDocument>;
        signed: boolean;
    };
    comments: IComment[];
}

export interface IRecordsState {
    error: string;
    total: number;
    isLoading: boolean;
    currentDate: string | null;
    patients: Array<IRecords>;
    times: Array<ITimeSpan>;
    doctors: Array<IRecords>;
    actionKey: ActionsKey | null;
    pagination: IPagination;
    filteredRecords: Array<IRecords> | null;
    patientDetails: IPatientDetails;
    consultationResult: IConsultationResultState;
    observation: Array<IObservationResponse>;
    medicalHistory: Array<IMedicalHistory>;
    consultationObservation: IObservationConsultation;
}

export interface IReferral {
    procedure: string | undefined;
    clinic: string;
    doctor: string;
    onPlatform: boolean;
    patient_id: string;
    consultation_id: string;
}

export interface IReferralResponse extends IReferral {
    id: string;
    doctor_id: string;
    createdAt: string;
}

export interface ITherapyState {
    procedure: string | undefined;
    dosage: string;
    regularity: string;
    fromDate: string | undefined;
    toDate: string | undefined;
    proceed: boolean;
    indication: string;
    patient_id: string;
    consultation_id: string;
}

export interface ITherapyResponse extends ITherapyState {
    createdAt: string;
    doctor_id: string;
    id: string;
    updatedAt: string;
}

export interface IMedicalHistory {
    comment: string;
    createdAt: string;
    date: string;
    diagnosis: string;
    doctor_id: string;
    end: number | string;
    id: string;
    documents: Array<IDocument>;
    isFree: boolean;
    isPromo: boolean;
    isSeason: boolean;
    patient_id: string;
    price: number;
    promo: null;
    season: null;
    signed: boolean;
    start: number | string;
    status: ConsultationStatus;
    type: "video chat" | "text chat";
}
