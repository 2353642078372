import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/redux";
import { useCountdown } from "../../hooks/useCountdown";
import { SendOtpSource } from "../../http/api";
import { sendVerifyCodeAction } from "../../store/actions/authActions";
import { authSlice } from "../../store/reducers/AuthSlice";

interface Props {
    login: string;
}

export const RepeatedSending: FC<Props> = ({ login }) => {
    const countdown = useCountdown(205);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onSendCodeAgain = async () => {
        try {
            await dispatch(sendVerifyCodeAction(login, SendOtpSource.REGISTER));
        } catch (error) {
            dispatch(authSlice.actions.setErrorMessage(t("The code is incorrect or invalid")));
        }
    };

    return (
        <span
            className={clsx(countdown ? "repeat__sending" : "send__code")}
            onClick={onSendCodeAgain}
        >
            {countdown ? t("Repeating sending", { countdown }) : t("resend")}
        </span>
    );
};
