import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FifthSection = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <div className="section-container">
                <div className="section-content">
                    <h1>
                        {t("Just download")} <span>{t("app")}</span> {t("for convenience")}
                    </h1>
                    <span>{t("Download the app to use our services easier and faster.")}</span>
                    <div className="section-apps">
                        <img src="/images/googlepay.png" alt="android" />
                        <img src="/images/appstore.png" alt="iOS" />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default FifthSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .section-container {
        max-width: 90%;
        width: 100%;
        min-height: 620px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        background-color: #f0f6ff;
        background-image: url("/images/section_back.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .section-content {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: center;
            padding-left: 100px;

            h1 {
                max-width: 570px;
                color: #212121;
                font-family: "Raleway";
                font-size: 50px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;

                span {
                    color: #4ce033;
                    font-weight: 800;
                }
            }

            & > span {
                max-width: 390px;
                color: #787878;
                font-family: "Roboto";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
            }

            .section-apps {
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: space-between;

                img {
                    max-width: 135px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        transform: translateY(-3px);
                    }
                }
            }
        }

        @media (max-width: 770px) {
            width: 95%;
            min-height: 400px;

            .section-content {
                width: auto;

                h1 {
                    font-size: 28px;
                }

                & > span {
                    font-size: 14px;
                }

                .section-apps {
                    img {
                        max-width: 200px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            transform: translateY(-3px);
                        }
                    }
                }
            }
        }

        @media (max-width: 450px) {
            width: 95%;
            min-height: auto;

            .section-content {
                padding: 40px 20px;
                width: auto;

                h1 {
                    font-size: 24px;
                }

                & > span {
                    font-size: 13px;
                }

                .section-apps {
                    img {
                        max-width: 150px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            transform: translateY(-3px);
                        }
                    }
                }
            }
        }
    }
`;
