export const Check = () => {
    return (
        <svg
            width="36"
            height="37"
            viewBox="0 0 36 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.3705 24.74C14.9312 24.74 14.4919 24.5733 14.1566 24.238L9.50301 19.5844C8.83233 18.9137 8.83233 17.8273 9.50301 17.1586C10.1737 16.4879 11.2582 16.4859 11.9288 17.1566L15.3705 20.5982L23.4657 12.503C24.1364 11.8323 25.2209 11.8323 25.8916 12.503C26.5622 13.1737 26.5622 14.2601 25.8916 14.9308L16.5844 24.238C16.249 24.5733 15.8098 24.74 15.3705 24.74Z"
                fill="white"
            />
        </svg>
    );
};
