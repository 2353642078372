import { FC } from "react";
import styled from "styled-components";

interface Props {
    height?: number;
    marginBottom?: number;
    marginTop?: number;
}

const Divider: FC<Props> = ({ height = 1, marginBottom = 0, marginTop = 0 }) => {
    return <CustomDivider height={height} marginBottom={marginBottom} marginTop={marginTop} />;
};

export default Divider;

const CustomDivider = styled.hr<{
    height?: number;
    marginBottom?: number;
    marginTop?: number;
}>`
    width: 100%;
    height: ${({ height }) => height + "px"};
    border: 1px solid rgb(238, 238, 238);
    margin-top: ${({ marginTop }) => marginTop + "px"};
    margin-bottom: ${({ marginBottom }) => marginBottom + "px"};
`;
