import type { AxiosResponse } from "axios";
import instance from "../http";
import { UserRoutes } from "../http/api";
import { PDFDocumentType } from "../models/recordsTypes";
import type { INotification, IUserProfile, UserResponse } from "../models/userTypes";

export default class UserService {
    static async getUser(token?: string | null): Promise<AxiosResponse<UserResponse>> {
        token && localStorage.setItem("token", token);
        return instance.get(UserRoutes.PROFILE);
    }

    static async updateUser(data: IUserProfile): Promise<AxiosResponse<UserResponse>> {
        return instance.patch(UserRoutes.PROFILE, data);
    }

    static async uploadMedia(formData: FormData): Promise<AxiosResponse<UserResponse>> {
        return instance.post(UserRoutes.UPLOAD, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    static async getPublicProfile(id: string): Promise<AxiosResponse> {
        return instance.get(UserRoutes.PUBLIC_PROFILE + "/" + id);
    }

    static async getNotification(): Promise<AxiosResponse<INotification[]>> {
        return instance.get(UserRoutes.NOTIFICATION);
    }

    static async updateNotification(data: string[]): Promise<AxiosResponse<INotification[]>> {
        return instance.patch(UserRoutes.NOTIFICATION, { ids: data });
    }

    static async removeNotification(id: string): Promise<AxiosResponse<INotification[]>> {
        return instance.delete(`${UserRoutes.NOTIFICATION}/${id}`);
    }

    static async removeAllNotifications(): Promise<AxiosResponse<INotification[]>> {
        return instance.delete(UserRoutes.NOTIFICATION);
    }

    static async getPDFFile(type: PDFDocumentType, id: string): Promise<AxiosResponse<string>> {
        return instance.get(`/v1/pdf/${type}/${id}`);
    }
}
