import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { t } from "i18next";
import { UserRole } from "../models/authTypes";
import { type INotification, NotifyEvents } from "../models/userTypes";
import { getUserFullName } from "./getUserFullName";

dayjs.extend(calendar);

export const getNotificationTitle = (notify: INotification, role: UserRole) => {
    let message = "";

    const messageDate = dayjs(notify.data?.date).calendar(null, {
        sameDay: `[${t("Today")}]`,
        nextDay: `[${t("Tomorrow")}]`,
        nextWeek: "dddd",
        lastDay: `[${t("Yesterday")}]`,
        lastWeek: `[${t("Last")}] dddd`,
        sameElse: "DD/MM/YYYY"
    });

    const name = getUserFullName(notify?.data?.[role]);

    if (notify?.event === NotifyEvents.REMINDER) {
        let consultationType = "онлайн-візит";

        if (notify.data?.type === "text chat") {
            consultationType = "візит через текстовий чат";
        }

        message = `${messageDate} o ${dayjs(+notify?.data?.start).format(
            "HH:mm"
        )} ${consultationType} до ${t(role)} ${name}`;
    } else {
        message = "Ваш рецепт на ліки вже готовий";
    }

    return message;
};
